<template>
  <div class="b2c-page-bg">
    <div v-if="step!=4" class="b2c-o-h">
      <validation-observer ref="infoValid">
        <div class="fs-sp">
          <div class="w-100 b2c-bg">
            <div class="w-100 b2c-bg-top">
              <div class="b2c-top-left" @click="isLoading=true;step=2;isLoading=false"></div>
              <div class="b2c-top-center">Giỏ hàng & thanh toán</div>
              <div class="b2c-top-right"></div>
            </div>
            <div class="b2c-text-solo pl-2 pr-2">
              Số thuê bao ngẫu nhiên của Quý khách là:<br>
              <span v-for="i in cart" :key="i.Phone" :product_link="`https://itel.vn/san-pham/${i.Phone}`"
                    class="ml-1 badge text-primary text_lg bg-white b2c-badge">{{ f087(i.Phone) }}</span><br>
              <a v-if="!ishowGetNumber" @click="search" class="text">
                <feather-icon icon="RefreshCcwIcon"/>
                Lấy số ngẫu nhiên khác</a>
            </div>
          </div>
          <div class="w-100 b2c-float-bg">
          </div>

          <div class="w-100 pb-2 pr-2 pl-2 pt-0">
            <template v-for="i in DataPackages.filter(p=>p.Name=='MAY')">
              <b-card body-class="p-1" class="b2c-package-bg-selected" :key="i.Name">
                <b-card-text>
                  <div class="d-flex border-bottom pb-1">
                    <div class="b2c-package-title-selected">{{ i.Name }}</div>
                    <div class="b2c-package-subtitle-selected">{{ i.Price|number }}đ<span
                        style="font-size:16px;padding-bottom:3px">/ tháng</span></div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div v-html="i.Brief" class="w-100 b2c-package-brief-selected"
                           style="padding:6px !important;line-height:1.5;margin-bottom:10px"></div>
                    </div>
                  </div>
                </b-card-text>
              </b-card>

            </template>
            <b-card v-if="!isLoading && cart.length>0 && DataPackages.length>0" body-class="p-1" class="b2c-cart-bg">
              <div class="row pt-1">
                <div class="col-6 h2">Số lượng SIM</b-form-checkbox></div>
                <div class="col-2 text-center" role="button">
                  <feather-icon size="24" @click="changeNumOfPhone(-1)" icon="MinusSquareIcon"/>
                </div>
                <div class="col-2 h1 text-center">{{ cart.length }}</div>
                <div class="col-2 text-center" role="button">
                  <feather-icon size="24" @click="changeNumOfPhone(1)" icon="PlusSquareIcon"/>
                </div>
              </div>
              <div class="row border-top mt-1">
                <div class="col-12 pt-2 b2c-agree-text">
                  <span class="float-left" v-if="paymentMethod!='COD'">
                    <b-form-checkbox name="vip" @change="changeEsim" v-model="cart_info.eSIM">Sử dụng eSIM<br><small
                        class="text-primary font-small-3">eSIM là SIM điện tử, được gửi về email của khách hàng <br/> Chỉ dùng cho các dòng máy hỗ trợ eSIM.</small></b-form-checkbox>
                  </span>
                </div>
              </div>
              <!--<div class="row pt-1">
                <div class="col-12 pb-1 h3"><b-form-checkbox @change="cartInfoChange(1)" name="vip" v-model="cart_info.data_hasESIM">Sử dụng eSIM</b-form-checkbox></div>
                <div class="col-3 h3"></div>
                <div class="col-1"><feather-icon size="24" @click="changeNumOfESim(-1)" icon="MinusSquareIcon"/></div>
                <div class="col-2 h1 text-center">{{cart.filter(m=>m.eSIM).length}}</div>
                <div class="col-1"><feather-icon size="24" @click="changeNumOfESim(1)" icon="PlusSquareIcon"/></div>
                <div class="col-5 h2 text-right text-primary" v-if="!isLoading">{{cart[0].EsimPrice * cart.filter(m=>m.eSIM).length|number}}đ</div>
              </div>-->
            </b-card>
          </div>
          <div class="w-100 b2c-cart-info-title">Thông tin giỏ hàng</div>
          <!--<validation-observer ref="infoValid">-->
          <div class="row p-2">
            <div class="col-md-6">
              <validation-provider #default="{ errors }" rules="required|min:3|space">
                <label class="d-block">Họ và tên <span class="text-primary">*</span></label>
                <b-form-input size="sm1" maxlength="35" v-model="cart_info.fullName"
                              :state="errors.length > 0 ? false : null"/>
                <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập đầy đủ họ và tên người nhận</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider #default="{ errors }" rules="required|min:10">
                <label class="d-block">Điện thoại liên hệ <span class="text-primary">*</span></label>
                <b-form-input size="sm1" type="text"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                              maxlength="10" v-model.trim="cart_info.ContactPhone"
                              :state="errors.length > 0 ? false : null"/>
                <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập số điện thoại liên hệ</small>
              </validation-provider>
            </div>
            <div class="col-md-12" v-if="cart.find(i=>i.eSIM)!=undefined">
              <validation-provider #default="{ errors }" rules="required|email">
                <label class="d-block">Email để nhận eSIM <span class="text-primary">*</span></label>
                <b-form-input size="sm1" maxlength="40" v-model.trim="cart_info.email"
                              :state="errors.length > 0 ? false : null"/>
                <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập chính xác email để nhận thông tin
                  eSIM</small>
              </validation-provider>
            </div>
          </div>
          <b-tabs
              active-nav-item-class="font-weight-bold text-danger"
              nav-class="b2c-cart-info-title-text shipping-nav"
              @input="onShipmentTabChange($event)"
              v-if="cart.find(i=>!i.eSIM)!=undefined"
              v-model="activeTab"
          >
            <b-tab title="Giao hàng tận nơi">
              <!-- <div v-if="cart.find(i=>!i.eSIM)!=undefined" class="w-100 b2c-cart-info-title">Giao hàng tận nơi
                <span class="float-right">
                  <b-form-checkbox @change="change_shipment('home')" name="shipment_type" value="home" v-model="cart_info.shipment_type"></b-form-checkbox>
                </span>
              </div> -->
              <div class="row p-1"
                   v-if="!isLoading && cart.find(i=>!i.eSIM)!=undefined && cart_info.shipment_type == 'home'">
                <!-- <div class="col-md-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                  <validation-provider rules="">
                    <label class="d-block">Thời gian<span class="text-primary">*</span></label>
                     <v-select 
                        label="text"
                        v-model="cart_info.deliver_work_ship" 
                        :filter="fuseSearch" 
                        :options="deliveryTimes" 
                        :reduce="option => option.id" 
                        placeholder="Chọn thời gian giao hàng"
                      />
                  </validation-provider>
                </div> -->
                <div class="col-md-12" v-if="cart.find(i=>!i.eSIM)!=undefined">
                  <validation-provider #default="{ errors }" rules="required|min:2">
                    <label class="d-block">Địa chỉ <span class="text-primary">*</span></label>
                    <b-form-input size="sm1" placeholder="Số nhà, tên đường, tên thôn/ấp/khu" maxlength="40"
                                  v-model="cart_info.addr" :state="errors.length > 0 ? false : null"/>
                    <small class="fs-error-txt" v-if="errors.length>0">Vui lòng điền chi tiết số nhà, tên đường,
                      thôn/ấp/khu</small>
                  </validation-provider>
                </div>
                <div class="col-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                  <validation-provider #default="{ errors }" rules="required|min0">
                    <label class="d-block">Tỉnh/TP <span class="text-primary">*</span></label>
                    <v-select @input="onCityChange" label="text" v-model="CityId"
                              :class="errors.length > 0 ? 'is-invalid' : ''" :filter="fuseSearch" :options="Cities"
                              :reduce="CityId => CityId.id"></v-select>
                    <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập Tỉnh/TP</small>
                  </validation-provider>
                </div>
                <div class="col-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                  <validation-provider #default="{ errors }" rules="required|min0">
                    <label class="d-block">Quận/Huyện <span class="text-primary">*</span></label>
                    <v-select :disabled="!(CityId>0)" :class="errors.length > 0 ? 'is-invalid' : ''"
                              :filter="fuseSearch" label="text" v-model="DistrictId" :options="Districts"
                              :reduce="c => c.id"></v-select>
                    <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập Quận/Huyện</small>
                  </validation-provider>
                </div>
                <div class="col-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                  <validation-provider #default="{ errors }" rules="required|min0">
                    <label class="d-block">Phường/Xã <span class="text-primary">*</span></label>
                    <v-select :disabled="!(DistrictId>0)" :class="errors.length > 0 ? 'is-invalid' : ''"
                              :filter="fuseSearch" label="text" v-model="WardId" :options="Wards"
                              :reduce="c => c.id"></v-select>
                    <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập Phường/Xã</small>
                  </validation-provider>
                </div>
                <div class="col-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                  <validation-provider #default="{ errors }" rules="">
                    <label class="d-block">Ấp/Khu</label>
                    <v-select :disabled="!(WardId>0)" :filter="fuseSearch" label="text" v-model="HamletId"
                              :options="Hamlets" :reduce="c => c.id"></v-select>
                    <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập Ấp/Khu</small>
                  </validation-provider>
                </div>
                <div class="col-12 mt-1"
                     v-if="paymentMethod != `COD` && cart.find(i=>!i.eSIM)!=undefined && hasExpressShip">
                  <div class="d-flex space-between delivery-type">
                    <div class="info-delivery">
                      <div class="info-delivery__title">Giao hàng hỏa tốc</div>
                      <div class="info-delivery__sub-title">{{ expressShipInfo.deleveryTime }}</div>
                    </div>
                    <div class="check-box mr-3" @click="onClickExpress()">
                      <img v-if="isExpress" alt="checkbox" src="../assets/images/check-circle-red.png"/>
                      <img v-else alt="checkbox" src="../assets/images/circleblank.png"/>
                    </div>

                  </div>
                  <div class="border-bt"></div>
                </div>
                <div class="col-12" v-if="cart.find(i=>!i.eSIM)!=undefined">
                  <div class="d-flex space-between delivery-type">
                    <div class="info-delivery">
                      <div class="info-delivery__title">Giao hàng nhanh</div>
                      <div class="info-delivery__sub-title">Nhận hàng từ 1 tới 3 ngày kể từ thời điểm đặt đơn hàng</div>
                    </div>
                    <div class="check-box mr-3" @click="onClickFast()">
                      <img v-if="isFast" alt="checkbox" src="../assets/images/check-circle-red.png"/>
                      <img v-else alt="checkbox" src="../assets/images/circleblank.png"/>
                    </div>
                  </div>
                  <div class="border-bt"></div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Nhận tại phòng giao dịch iTel">
              <!-- <div v-if="cart.find(i=>!i.eSIM)!=undefined" class="w-100 b2c-cart-info-title border">Nhận tại phòng giao dịch iTel
                <span class="float-right">
                  <b-form-checkbox @change="change_shipment('itel')" name="shipment_type" value="itel" v-model="cart_info.shipment_type"></b-form-checkbox>
                </span>
              </div> -->
              <b-row v-if="cart.find(i=>!i.eSIM)!=undefined">
                <div class="col-12">
                  <div @click="storeCollapse=!storeCollapse"
                       :class="storeCollapse?'b2c-store-colapse':'b2c-store-nocolapse'"
                       class="border d-block bg-white p-2">
                    <span
                        v-if="!isLoading && cart_info.StoreId>0">{{ Stores.find(m => m.Id == cart_info.StoreId).Name }}</span>
                    <span v-else>Chọn chi nhánh</span>
                  </div>
                </div>
              </b-row>
              <div v-if="!isLoading && !storeCollapse && cart.find(i=>!i.eSIM)!=undefined">
                <div v-on:click="selectStore(i)" class=" ml-1 mr-1 p-2 bg-white border" v-for="i in Stores" :key="i.Id">
                  <h2 class="h2 pt-1">{{ i.Name }}</h2>
                  <p class="text">Giờ làm việc: thứ 2 - thứ 6 (trừ ngày lễ, Tết)</p>
                  <p class="text">Sáng: 8h-12h, Chiều: 13h-18h (thứ 6 làm việc đến 17h)</p>
                  <p class="pt-1 text text-primary pb-1"><a target="_blank" class="text text-primary"
                                                            :href="'http://maps.google.com/maps/dir/?api=1&destination='+i.Lat+','+i.Long+'&travelmode=driving'"
                                                            :title="$t('Common.Direct')">
                    <feather-icon icon="MapPinIcon"/>
                    {{ $t('Common.Direct') }}</a></p>
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <!-- <b-row>
            <b-col cols="12" class="p-3">
              <div class="border rounded bg-white p-3">
                <b-form-checkbox :value="true" v-model="cart_info.agree">
                  Tôi đồng ý với <a href="https://itel.vn/thong-tin/dieu-khoan-giao-dich-chung" class="text-primary" target="_blank">chính sách mua SIM số</a> của iTel
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row> -->
          <b-row class="pt-2 pl-3 pr-3 bg-white">
            <table class="w-100 ml-2 mr-2 mb-2" v-if="cart.length>0">
              <tr>
                <td class="text p-1">SIM DATA<br><span class="h2">{{ cart[0].Pack }}</span></td>
                <td class="h2">{{ cart.length }}</td>
                <td class="h2 text-right p-1">{{ getTotalWithoutEsim()|number }}đ</td>
              </tr>
              <tr class="bg-light text">
                <td class="p-1">Phụ phí eSIM</td>
                <td class="h2">{{ cart.filter(m => m.eSIM).length }}</td>
                <td class="h2 text-right p-1">
                  {{ cart.filter(x => x.eSIM).length * (cart[0].EsimPrice - cart[0].SimPrice)|number }}đ
                </td>
              </tr>
            </table>
          </b-row>
          <div class="w-100 b2c-cart-info-title border">
            <span>Chọn hình thức thanh toán</span>
          </div>
          <b-row>
            <b-col cols="12" class="p-3">
              <div class="bg-white rounded">
                <div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox"
                     @click="ChangePaymentMethod('VNPAY')">
                  <div class="d-flex align-items-center ">
                    <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/VNPay.svg')"
                           alt="logoVNPay"/>
                    <span class="inputText mr-2">Thanh toán qua </span>
                    <b-img width="71px" height="71px" :src="require('@/assets/images/b2c/logoVNPay.svg')"
                           alt="logoVNPay"/>
                  </div>
                  <b-form-checkbox value="VNPAY" v-model="paymentMethod" class="vs-checkbox-con" disabled>
                  </b-form-checkbox>
                </div>
                <div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox"
                     @click="ChangePaymentMethod('BANK')">
                  <div class="d-flex align-items-center ">
                    <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/momo.svg')"
                           alt="logoVNPay"/>
                    <span class="inputText">Qua thẻ ATM (có Internet Banking)</span>
                  </div>
                  <b-form-checkbox value="BANK" v-model="paymentMethod" disabled>
                  </b-form-checkbox>
                </div>
                <div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox"
                     @click="ChangePaymentMethod('INTCARD')">
                  <div class="d-flex align-items-center ">
                    <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/momo.svg')"
                           alt="logoVNPay"/>
                    <span class="inputText mr-2">Qua thẻ thanh toán quốc tế</span>
                    <b-img width="100px" height="43px" :src="require('@/assets/images/b2c/ListCard.png')"
                           alt="IntCard"/>
                  </div>
                  <b-form-checkbox value="INTCARD" v-model="paymentMethod" disabled>
                  </b-form-checkbox>

                </div>
                <div style="margin-bottom: 2%" class="borderPaymentBox">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center ">
                      <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/ViDienTu.svg')"
                             alt="logoVNPay"/>
                      <span class="inputText"> Thanh toán với ví điện tử</span>
                    </div>
                    <b-form-checkbox v-model="isZaloMono" disabled>
                    </b-form-checkbox>
                  </div>
                  <div>
                    <button style="margin-left: 3%" class="buttonZaloMomo" @click="ChangePaymentMethod('ZALO')"
                            :class="paymentMethod==='ZALO'?'buttonZaloMomoActive':''">
                      <b-img width="150px" height="42px" :src="require('@/assets/images/b2c/LogoZalo.png')"
                             alt="logoZalo" class="zaloLogo"/>
                    </button>
                    <button class="buttonZaloMomo" @click="ChangePaymentMethod('MOMO')"
                            :class="paymentMethod==='MOMO'?'buttonZaloMomoActive':''">
                      <b-img height="57px" width="57px" :src="require('@/assets/images/b2c/LoloMomo.png')"
                             alt="logoMomo" class="momoLogo"/>
                    </button>
                  </div>
                </div>
                <div class="borderPaymentBox">
                  <div v-if="!isLoading && cart.find(i=>!i.eSIM)!=undefined" style="height: 52px;"
                       class="d-flex justify-content-between align-items-center" @click="ChangePaymentMethod('COD')">
                    <div class="d-flex align-items-center">
                      <b-img width="30px" height="30px" :src="require('@/assets/images/b2c/COD.svg')" alt="logoCOD"
                             class="ml-1"/>
                      <span class="inputText ml-2"> Thanh toán khi nhận hàng (COD)</span>
                    </div>
                    <b-form-checkbox value="COD" v-model="paymentMethod" disabled>
                    </b-form-checkbox>
                  </div>
                  <div v-show="paymentMethod === 'COD' && activeTab === 1"
                       style="padding: 10px; font-size: 14px; font-style: italic;">
                    <p>(Quý Khách vui lòng đến PGD đã chọn để nhận hàng. iTel sẽ giữ đơn hàng của Quý Khách trong vòng
                      48h, sau thời điểm trên đơn hàng sẽ bị hủy.)</p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="border-top pt-2 pl-3 pr-3">
            <b-col cols="6" class="h2">Phí vận chuyển</b-col>
            <b-col cols="6" class="h2 text-right">
              <p v-if="cart_info.ghtk_fee!=undefined || ( !isFullEsim() && isExpress && cart_info.shipment_type=='home')"
                 class="b2c-ghtk-fee">
                {{
                  (!isFullEsim() && isExpress && cart_info.shipment_type == 'home') ? formatNumber(expressShipInfo.ahamoveFee) :
                      (isLoadingFee ? 0 : formatNumber(cart_info.ghtk_fee))
                }}đ</p>
              {{
                (!isFullEsim() && isExpress && cart_info.shipment_type == 'home') ? formatNumber(expressShipInfo.lastFee) :
                    formatNumber(getShipmentFee())
              }}đ
            </b-col>
            <b-col cols="6" class="h1 pt-1">Tổng tiền</b-col>
            <b-col cols="6" class="huge-text pt-1 text-primary text-right" style="font-size: 24px;">
              {{ getTotalPrice() - totalSalePrice| number }}đ
              <p v-if="isSale || isStatusUrbox" class="b2c-ghtk-fee" style="color: #5E5873;">{{ totalSalePrice| number }}đ</p>
            </b-col>
            <b-col cols="12" class="d-flex mb-1">
              <b-form-input size="sm1" placeholder="Nhập mã ưu đãi" style="width: 85%;" v-model="promotionCode" @update="handlePromotionCode()"/>
              <b-button :disabled="promotionCode === null || promotionCode === ''" @click="getStatusPromotionCode()"
                        class="b2c__btn"
                        :style="promotionCode === null || promotionCode === '' ? '' : 'background: #ed1f24 !important;'">
                Áp dụng
              </b-button>
            </b-col>
            <b-col cols="12">
              <p v-if="isStatusGPMN" style="color: blue; font-size: 16px;">Mã ưu đãi áp dụng thành công</p>
              <p v-if="isStatusUrbox" style="color: blue; font-size: 16px;">Ưu đãi dành cho khách hàng có mã giảm giá của UrBox</p>
              <p v-if="isStatusPromotionCode" style="color: blue; font-size: 16px;">Giảm 30% giá SIM (không bao gồm giá
                gói cước và phí ship) tối đa 30.000đ</p>
              <p v-if="isItelSCode " style="color: blue; font-size: 16px;">Ưu đãi dành cho khách hàng có mã giảm giá của
                Samsung</p>
              <p v-if="isVinID" style="color: blue; font-size: 16px;">Ưu đãi dành cho khách hàng có mã giảm giá của
                VinID</p>
              <p v-if="isOppo" style="color: blue; font-size: 16px;">Ưu đãi dành cho khách hàng có mã giảm giá của
                OPPO</p>
            </b-col>
          </b-row>
          <b-row hidden>
            <div class="p-2 pr-4 pl-4 w-100">
              <div class="border d-block bg-white rounded text-center arrow-icon-end">
                <span>Áp dụng ưu đãi để được giảm giá</span>
              </div>
            </div>
          </b-row>
          <button @click="onPayment()" class="b2c-search-button b2c-search-button-red">{{ payment_text }}</button>
          <b-row>
            <div class="pl-4 pr-4 w-100">
              <div class="d-block text-center pb-3">
                Bằng cách bấm thanh toán, bạn đồng ý với <a
                  href="https://itel.vn/thong-tin/dieu-kien-&-dieu-khoan-giao-dich-chung"
                  class="text-primary text-decoration-underline" target="_blank">điều khoản và điều kiện giao dịch
                chung</a> của
                iTel
              </div>
            </div>
          </b-row>
        </div>
        <div class="fs-pc">
          <div class="b2c-pc-pack-content">
            <div class="row">
              <div class="col-6 b2c-pc-payment">
                <div class="package">
                  <div class="b2c-text-solo pt-2 pl-2 pr-2">
                    Số thuê bao {{ !ishowGetNumber ? "ngẫu nhiên" : "" }} của Quý khách là:<br>
                    <span v-for="i in cart" :key="i.Phone"
                          class="ml-1 badge text-primary text_lg bg-white b2c-badge">{{ f087(i.Phone) }}</span><br>
                    <a v-if="!ishowGetNumber" @click="search" class="text">
                      <feather-icon icon="RefreshCcwIcon"/>
                      Lấy số ngẫu
                      nhiên khác</a>
                  </div>
                  <div class="w-100 pb-2 pr-2 pl-2 pt-2">
                    <template v-for="i in DataPackages.filter(p=>p.Name=='MAY')">
                      <b-card body-class="p-1" class="b2c-package-bg-selected p-1" :key="i.Name">
                        <b-card-text>
                          <div class="d-flex border-bottom pb-1">
                            <div class="b2c-package-title-selected">{{ i.Name }}</div>
                            <div class="b2c-package-subtitle-selected">{{ i.Price|number }}đ<span
                                style="font-size:16px">/
                                            tháng</span></div>
                          </div>
                          <div class="row">
                            <div class="col-12 border-bottom mb-2">
                              <div v-html="i.Brief" class="w-100 b2c-package-brief-selected"
                                   style="padding:6px !important;line-height:1.5;margin-bottom:10px"></div>
                            </div>
                          </div>
                          <div class="row pt-1">
                            <div class="col-6 h2">Số lượng SIM</b-form-checkbox></div>
                            <div class="col-2 text-center" role="button">
                              <feather-icon size="24" @click="changeNumOfPhone(-1)"
                                            icon="MinusSquareIcon"/>
                            </div>
                            <div class="col-2 h1 text-center">{{ cart.length }}</div>
                            <div class="col-2 text-center" role="button">
                              <feather-icon size="24" @click="changeNumOfPhone(1)"
                                            icon="PlusSquareIcon"/>
                            </div>
                          </div>
                          <div class="row border-top mt-1">
                            <div class="col-12 pt-2 b2c-agree-text">
                                          <span class="float-left" v-if="paymentMethod!='COD'">
                                            <b-form-checkbox name="vip" @change="changeEsim" v-model="cart_info.eSIM">Sử dụng eSIM<br><small
                                                class="text-primary font-small-3">eSIM là SIM điện tử, được gửi về email của khách hàng
                                                <br/> Chỉ dùng cho các dòng máy hỗ trợ eSIM.</small></b-form-checkbox>
                                          </span>
                            </div>
                          </div>
                        </b-card-text>
                      </b-card>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-6 b2c-pc-info">
                <b-card class="p-0" body-class="p-0">
                  <b-card-header v-if="cart.find(i=>i.eSIM)!=undefined">Thông tin nhận eSIM</b-card-header>
                  <b-card-header v-else>Thông tin người nhận</b-card-header>
                  <b-card-text>
                    <!--<validation-observer ref="infoValid">-->
                    <div class="row p-2">
                      <div class="col-md-6">
                        <validation-provider #default="{ errors }" rules="required|min:3|space">
                          <label class="d-block">Họ và tên <span class="text-primary">*</span></label>
                          <b-form-input maxlength="35" size="sm1" v-model="cart_info.fullName"
                                        :state="errors.length > 0 ? false : null"/>
                          <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập đầy đủ họ và tên người
                            nhận</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-6">
                        <validation-provider #default="{ errors }" rules="required|min:10">
                          <label class="d-block">Điện thoại liên hệ <span class="text-primary">*</span></label>
                          <b-form-input size="sm1" type="text"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                        maxlength="10" v-model.trim="cart_info.ContactPhone"
                                        :state="errors.length > 0 ? false : null"/>
                          <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập số điện thoại liên hệ</small>
                        </validation-provider>
                      </div>
                      <div class="col-md-12" v-if="cart.find(i=>i.eSIM)!=undefined">
                        <validation-provider #default="{ errors }" rules="required|email">
                          <label class="d-block">Email để nhận eSIM <span class="text-primary">*</span></label>
                          <b-form-input size="sm1" maxlength="40" v-model.trim="cart_info.email"
                                        :state="errors.length > 0 ? false : null"/>
                          <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập chính xác email để nhận thông
                            tin
                            eSIM</small>
                        </validation-provider>
                      </div>
                    </div>
                    <b-tabs active-nav-item-class="font-weight-bold text-danger"
                            nav-class="b2c-cart-info-title-text shipping-nav" @input="onShipmentTabChange($event)"
                            v-if="cart.find(i=>!i.eSIM)!=undefined" v-model="activeTab">
                      <b-tab title="Giao hàng tận nơi">
                        <!--</validation-observer>-->
                        <!-- <div v-if="cart.find(i=>!i.eSIM)!=undefined" class="w-100 b2c-cart-info-title">Giao hàng tận nơi
                                                                                                                                        <span class="float-right">
                                                                                                                                          <b-form-checkbox @change="change_shipment('home')" name="shipment_type" value="home" v-model="cart_info.shipment_type"></b-form-checkbox>
                                                                                                                                        </span>
                                                                                                                                      </div> -->
                        <!--<validation-observer ref="addrValid">-->
                        <div class="row p-2"
                             v-if="!isLoading && cart_info.shipment_type=='home' && cart.find(i=>!i.eSIM)!=undefined">
                          <!-- <div class="col-md-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                            <validation-provider rules="">
                              <label class="d-block">Thời gian<span class="text-primary">*</span></label>
                               <v-select 
                                label="text"
                                v-model="cart_info.deliver_work_ship" 
                                :filter="fuseSearch" 
                                :options="deliveryTimes" 
                                :reduce="option => option.id" 
                                placeholder="Chọn thời gian giao hàng"
                              />
                            </validation-provider>
                          </div> -->
                          <div class="col-md-12" v-if="cart.find(i=>!i.eSIM)!=undefined">
                            <validation-provider #default="{ errors }" rules="required|min:2">
                              <label class="d-block">Địa chỉ <span class="text-primary">*</span></label>
                              <b-form-input size="sm1" placeholder="Số nhà, tên đường, tên thôn/ấp/khu" maxlength="40"
                                            v-model="cart_info.addr" :state="errors.length > 0 ? false : null"/>
                              <small class="fs-error-txt" v-if="errors.length>0">Vui lòng điền chi tiết số nhà, tên
                                đường,
                                thôn/ấp/khu</small>
                            </validation-provider>
                          </div>
                          <div class="col-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                            <validation-provider #default="{ errors }" rules="required|min0">
                              <label class="d-block">Tỉnh/TP <span class="text-primary">*</span></label>
                              <v-select @input="onCityChange" label="text"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                        v-model="CityId" :filter="fuseSearch" :options="Cities"
                                        :reduce="CityId => CityId.id"></v-select>
                              <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập Tỉnh/TP</small>
                            </validation-provider>
                          </div>
                          <div class="col-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                            <validation-provider #default="{ errors }" rules="required|min0">
                              <label class="d-block">Quận/Huyện <span class="text-primary">*</span></label>
                              <v-select :disabled="!(CityId>0)" :class="errors.length > 0 ? 'is-invalid' : ''"
                                        :filter="fuseSearch" label="text" v-model="DistrictId" :options="Districts"
                                        :reduce="c => c.id"></v-select>
                              <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập Quận/Huyện</small>
                            </validation-provider>
                          </div>
                          <div class="col-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                            <validation-provider #default="{ errors }" rules="required|min0">
                              <label class="d-block">Phường/Xã <span class="text-primary">*</span></label>
                              <v-select :disabled="!(DistrictId>0)" :class="errors.length > 0 ? 'is-invalid' : ''"
                                        :filter="fuseSearch" label="text" v-model="WardId" :options="Wards"
                                        :reduce="c => c.id"></v-select>
                              <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập Phường/Xã</small>
                            </validation-provider>
                          </div>
                          <div class="col-6" v-if="cart.find(i=>!i.eSIM)!=undefined">
                            <validation-provider #default="{ errors }" rules="">
                              <label class="d-block">Ấp/Khu</label>
                              <v-select :disabled="!(WardId>0)" :filter="fuseSearch" label="text" v-model="HamletId"
                                        :options="Hamlets" :reduce="c => c.id"></v-select>
                              <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập Ấp/Khu</small>
                            </validation-provider>
                          </div>
                          <div class="col-12 mt-1">
                            <div
                                :class="[ (CityId>0) && cart.find(i=>!i.eSIM)!=undefined && hasExpressShip && paymentMethod!='COD' ? '' : 'disabled' , ' d-flex space-between delivery-type']"
                                v-if="(CityId>0)">
                              <div class="info-delivery">
                                <div class="info-delivery__title">Giao hàng hỏa tốc <span
                                    class="info-delivery--subtile ">(Không
                                                áp dụng với hình thức thanh toán COD) <br/> <i
                                      v-if="!hasExpressShip && !paymentMethod!='COD' && !cart.find(i=>!i.eSIM)!=undefined"> Chưa
                                                  hỗ trợ tại tỉnh thành đang chọn </i> </span></div>
                                <div class="info-delivery__sub-title" disabled
                                     v-if="cart.find(i=>!i.eSIM)!=undefined && hasExpressShip && paymentMethod!='COD'">
                                  {{ expressShipInfo.deleveryTime }}
                                </div>
                              </div>
                              <div class="check-box mr-3" @click="onClickExpress()"
                                   v-if="cart.find(i=>!i.eSIM)!=undefined && hasExpressShip && paymentMethod!='COD'">
                                <img v-if="isExpress" alt="checkbox" src="../assets/images/check-circle-red.png"/>
                                <img v-else alt="checkbox" src="../assets/images/circleblank.png"/>
                              </div>
                              <div class="check-box mr-3" v-else>
                                <img alt="checkbox" src="../assets/images/circleblank.png"/>
                              </div>
                            </div>
                            <div class="border-bt"></div>
                          </div>
                          <div class="col-12" v-if="cart.find(i=>!i.eSIM)!=undefined">
                            <div class="d-flex space-between delivery-type">
                              <div class="info-delivery">
                                <div class="info-delivery__title">Giao hàng nhanh</div>
                                <div class="info-delivery__sub-title">Nhận hàng từ 1 tới 3 ngày kể từ thời điểm đặt đơn
                                  hàng
                                </div>
                              </div>
                              <div class="check-box mr-3" @click="onClickFast()">
                                <img v-if="isFast" alt="checkbox" src="../assets/images/check-circle-red.png"/>
                                <img v-else alt="checkbox" src="../assets/images/circleblank.png"/>
                              </div>
                            </div>
                            <div class="border-bt"></div>
                          </div>
                        </div>
                        <!--</validation-observer>-->
                      </b-tab>
                      <b-tab title="Nhận tại phòng giao dịch iTel">
                        <!-- <div v-if="cart.find(i=>!i.eSIM)!=undefined" class="w-100 b2c-cart-info-title border">Nhận tại phòng giao dịch iTel
                                                                                                                                        <span class="float-right">
                                                                                                                                          <b-form-checkbox @change="change_shipment('itel')" name="shipment_type" value="itel" v-model="cart_info.shipment_type"></b-form-checkbox>
                                                                                                                                        </span>
                                                                                                                                      </div> -->
                        <b-row v-if="cart.find(i=>!i.eSIM)!=undefined">
                          <div class="col-12 p-2">
                            <div @click="storeCollapse=!storeCollapse"
                                 :class="storeCollapse?'b2c-store-colapse':'b2c-store-nocolapse'"
                                 class="border d-block bg-white p-2">
                                          <span
                                              v-if="!isLoading && cart_info.StoreId>0">{{ Stores.find(m => m.Id == cart_info.StoreId).Name }}</span>
                              <span v-else>Chọn chi nhánh</span>
                            </div>
                          </div>
                        </b-row>
                        <div v-if="!isLoading && !storeCollapse && cart.find(i=>!i.eSIM)!=undefined">
                          <div v-on:click="selectStore(i)" class=" ml-1 mr-1 p-2 bg-white border" v-for="i in Stores"
                               :key="i.Id">
                            <h2 class="h2 pt-1">{{ i.Name }}</h2>
                            <p class="text">Giờ làm việc: thứ 2 - thứ 6 (trừ ngày lễ, Tết)</p>
                            <p class="text">Sáng: 8h-12h, Chiều: 13h-18h (thứ 6 làm việc đến 17h)</p>
                            <p class="pt-1 text text-primary pb-1"><a target="_blank" class="text text-primary"
                                                                      :href="'http://maps.google.com/maps/dir/?api=1&destination='+i.Lat+','+i.Long+'&travelmode=driving'"
                                                                      :title="$t('Common.Direct')">
                              <feather-icon icon="MapPinIcon"/>
                              {{ $t('Common.Direct') }}</a>
                            </p>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>

                    <!-- <b-row>
                                                                                                                                  <b-col cols="12" class="pl-3 pr-3 pt-3 pb-0">
                                                                                                                                    <div class="border rounded bg-white p-3">
                                                                                                                                      <b-form-checkbox :value="true" v-model="cart_info.agree">
                                                                                                                                        Tôi đồng ý với <a href="https://itel.vn/thong-tin/dieu-khoan-giao-dich-chung" class="text-primary" target="_blank">chính sách mua SIM số</a> của iTel
                                                                                                                                      </b-form-checkbox>
                                                                                                                                    </div>
                                                                                                                                  </b-col>
                                                                                                                                </b-row> -->
                    <b-row class="pt-2 pl-3 pr-3">
                      <table class="w-100 ml-2 mr-2 mb-2" v-if="cart.length>0">
                        <tr>
                          <td class="text p-1">SIM DATA<br><span class="h2">{{ cart[0].Pack }}</span></td>
                          <td class="h2">{{ cart.length }}</td>
                          <td class="h2 text-right p-1">{{ getTotalWithoutEsim()|number }}đ</td>
                        </tr>
                        <tr class="bg-light text">
                          <td class="p-1">Phụ phí eSIM</td>
                          <td class="h2">{{ cart.filter(m => m.eSIM).length }}</td>
                          <td class="h2 text-right p-1">{{
                              cart.filter(x => x.eSIM).length * (cart[0].EsimPrice - cart[0].SimPrice
                              )|number
                            }}đ
                          </td>
                        </tr>
                      </table>
                    </b-row>
                    <div class="w-100 b2c-cart-info-title border">
                      <span>Chọn hình thức thanh toán</span>
                    </div>
                    <b-row>
                      <b-col cols="12" class="p-3">
                        <div class="bg-white rounded">
                          <div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox"
                               @click="ChangePaymentMethod('VNPAY')">
                            <div class="d-flex align-items-center ">
                              <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/VNPay.svg')"
                                     alt="logoVNPay"/>
                              <span class="inputText mr-2">Thanh toán qua </span>
                              <b-img width="71px" height="71px" :src="require('@/assets/images/b2c/logoVNPay.svg')"
                                     alt="logoVNPay"/>
                            </div>
                            <b-form-checkbox value="VNPAY" v-model="paymentMethod" class="vs-checkbox-con" disabled>
                            </b-form-checkbox>
                          </div>
                          <div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox"
                               @click="ChangePaymentMethod('BANK')">
                            <div class="d-flex align-items-center ">
                              <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/momo.svg')"
                                     alt="logoVNPay"/>
                              <span class="inputText">Qua thẻ ATM (có Internet Banking)</span>
                            </div>
                            <b-form-checkbox value="BANK" v-model="paymentMethod" disabled>
                            </b-form-checkbox>
                          </div>
                          <div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox"
                               @click="ChangePaymentMethod('INTCARD')">
                            <div class="d-flex align-items-center ">
                              <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/momo.svg')"
                                     alt="logoVNPay"/>
                              <span class="inputText mr-2">Qua thẻ thanh toán quốc tế</span>
                              <b-img width="100px" height="43px" :src="require('@/assets/images/b2c/ListCard.png')"
                                     alt="IntCard"/>
                            </div>
                            <b-form-checkbox value="INTCARD" v-model="paymentMethod" disabled>
                            </b-form-checkbox>

                          </div>
                          <div style="margin-bottom: 2%" class="borderPaymentBox">
                            <div class="d-flex justify-content-between align-items-center">
                              <div class="d-flex align-items-center ">
                                <b-img width="52px" height="52px" :src="require('@/assets/images/b2c/ViDienTu.svg')"
                                       alt="logoVNPay"/>
                                <span class="inputText"> Thanh toán với ví điện tử</span>
                              </div>
                              <b-form-checkbox v-model="isZaloMono" disabled>
                              </b-form-checkbox>
                            </div>
                            <div>
                              <button style="margin-left: 3%" class="buttonZaloMomo"
                                      @click="ChangePaymentMethod('ZALO')"
                                      :class="paymentMethod==='ZALO'?'buttonZaloMomoActive':''">
                                <b-img width="150px" height="42px" :src="require('@/assets/images/b2c/LogoZalo.png')"
                                       alt="logoZalo" class="zaloLogo"/>
                              </button>
                              <button class="buttonZaloMomo" @click="ChangePaymentMethod('MOMO')"
                                      :class="paymentMethod==='MOMO'?'buttonZaloMomoActive':''">
                                <b-img height="57px" width="57px" :src="require('@/assets/images/b2c/LoloMomo.png')"
                                       alt="logoMomo" class="momoLogo"/>
                              </button>
                            </div>
                          </div>
                          <div class="borderPaymentBox">
                            <div v-if="!isLoading && cart.find(i=>!i.eSIM)!=undefined" style="height: 52px;"
                                 class="d-flex justify-content-between align-items-center"
                                 @click="ChangePaymentMethod('COD')">
                              <div class="d-flex align-items-center">
                                <b-img width="30px" height="30px" :src="require('@/assets/images/b2c/COD.svg')"
                                       alt="logoCOD"
                                       class="ml-1"/>
                                <span class="inputText ml-2"> Thanh toán khi nhận hàng (COD)</span>
                              </div>
                              <b-form-checkbox value="COD" v-model="paymentMethod" disabled>
                              </b-form-checkbox>
                            </div>
                            <div v-show="paymentMethod === 'COD' && activeTab === 1"
                                 style="padding: 10px; font-size: 14px; font-style: italic;">
                              <p>(Quý Khách vui lòng đến PGD đã chọn để nhận hàng. iTel sẽ giữ đơn hàng của Quý Khách
                                trong vòng 48h, sau thời điểm trên đơn hàng sẽ bị hủy.)</p>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="border-top pt-2 pl-3 pr-3">
                      <b-col cols="6" class="h2">Phí vận chuyển</b-col>
                      <b-col cols="6" class="h2 text-right">
                        <p v-if="cart_info.ghtk_fee!=undefined || ( !isFullEsim() && isExpress && cart_info.shipment_type=='home')"
                           class="b2c-ghtk-fee">
                          {{ (!isFullEsim() && isExpress && cart_info.shipment_type == 'home') ? formatNumber(expressShipInfo.ahamoveFee) : (isLoadingFee ? 0 : formatNumber(cart_info.ghtk_fee)) }}đ
                        </p>
                        {{
                          (!isFullEsim() && isExpress && cart_info.shipment_type == 'home') ? formatNumber(expressShipInfo.lastFee) : formatNumber(getShipmentFee())
                        }}đ
                      </b-col>
                      <b-col cols="6" class="h1 pt-1">Tổng tiền</b-col>
                      <b-col cols="6" class="huge-text pt-1 text-primary text-right" style="font-size: 24px;">
                        {{ getTotalPrice() - totalSalePrice |number }}đ
                        <p v-if="isSale || isStatusUrbox" class="b2c-ghtk-fee" style="color: #5E5873;">{{ totalSalePrice| number }}đ</p>
                      </b-col>
                      <b-col cols="12" class="d-flex mb-1">
                        <b-form-input size="sm1" placeholder="Nhập mã ưu đãi" style="width: 85%;"
                                      v-model="promotionCode" @update="handlePromotionCode()"/>
                        <b-button :disabled="promotionCode === null || promotionCode === ''"
                                  @click="getStatusPromotionCode()" class="b2c__btn"
                                  :style="promotionCode === null || promotionCode === '' ? '' : 'background: #ed1f24 !important;'">
                          Áp dụng
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <p v-if="isStatusGPMN" style="color: blue; font-size: 16px;">Mã ưu đãi áp dụng thành công</p>
                        <p v-if="isStatusUrbox" style="color: blue; font-size: 16px;">Ưu đãi dành cho khách hàng có mã giảm giá của UrBox</p>
                        <p v-if="isStatusPromotionCode" style="color: blue; font-size: 16px;">Giảm 30% giá SIM (không
                          bao gồm giá gói cước và phí ship) tối đa 30.000đ</p>
                        <p v-if="isItelSCode " style="color: blue; font-size: 16px;">Ưu đãi dành cho khách hàng có mã
                          giảm giá của Samsung</p>
                        <p v-if="isVinID" style="color: blue; font-size: 16px;">Ưu đãi dành cho khách hàng có mã giảm
                          giá của VinID</p>
                        <p v-if="isOppo" style="color: blue; font-size: 16px;">Ưu đãi dành cho khách hàng có mã giảm giá
                          của OPPO</p>
                      </b-col>
                    </b-row>
                    <b-row hidden>
                      <div class="p-2 pl-4 pr-4 w-100">
                        <div class="border d-block bg-white rounded text-center arrow-icon-end">
                          <span>Áp dụng ưu đãi để được giảm giá</span>
                        </div>
                      </div>
                    </b-row>
                    <button @click="onPayment()" class="b2c-search-button b2c-search-button-red">{{ payment_text }}
                    </button>
                    <b-row>
                      <div class="pl-4 pr-4 w-100">
                        <div class="d-block bg-white text-center pb-3">
                          Bằng cách bấm thanh toán, bạn đồng ý với <a
                            href="https://itel.vn/thong-tin/dieu-kien-&-dieu-khoan-giao-dich-chung"
                            class="text-primary text-decoration-underline" target="_blank">điều khoản và điều kiện giao
                          dịch chung</a> của iTel
                        </div>
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
    <div class="p-2" v-else-if="step==4">

      <div v-if="VnPayResponse" class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="pb-3 pt-3 w-100 text-center border-bottom" v-if="VnPayResponse.vnp_ResponseCode=='00'">
            <h2 class="huge-text">
              <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M117.143 54.7756V60.0327C117.136 72.3552 113.146 84.3452 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7644 2.17024 68.536 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8732C24.5677 13.506 35.3873 6.97778 47.4068 4.26215C59.4262 1.54651 72.0015 2.78895 83.2572 7.80417M117.143 14.3185L60 71.5185L42.8571 54.3757"
                    stroke="#18D3C9" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <br><br>Hoàn tất đơn hàng
            </h2>
            <p class="text">Chúc mừng bạn đã sở hữu SIM iTel. Quý Khách vui lòng thực hiện kích hoạt sim trong vòng 20
              ngày kể từ ngày đặt hàng. Quá thời gian trên, iTel sẽ tiến hành thu hồi số thuê bao theo quy định</p>
          </div>
          <div class="pb-3 pt-3 w-100 text-center border-bottom" v-else>
            <h2 class="huge-text">
              <svg width="127" height="127" viewBox="0 0 127 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M81.8216 44.75L45.1787 81.3929M45.1787 44.75L81.8216 81.3929M124.572 63.0714C124.572 96.8002 97.229 124.143 63.5001 124.143C29.7713 124.143 2.42871 96.8002 2.42871 63.0714C2.42871 29.3426 29.7713 2 63.5001 2C97.229 2 124.572 29.3426 124.572 63.0714Z"
                    stroke="#ED1F24" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <br><br>Thanh toán thất bại
            </h2>
          </div>
          <div v-if="VnPayResponse.vnp_ResponseCode=='00'" class="p-0">
            <b-card>
              <b-card-text>
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng
                  </b-col>
                  <b-col cols="6" class="pl-0 h1 text-right text-primary">{{ VnPayResponse.vnp_TxnRef }}</b-col>
                </b-row>
                <b-row class="pt-0">
                  <b-col cols="12" class="text text-primary hidden">Bạn lưu ý ghi nhớ mã đơn hàng để phục vụ kích hoạt
                    SIM
                  </b-col>
                </b-row>
                <b-row class="pt-0 pb-1 border-bottom">
                  <b-col cols="6" class="h2">Đã thanh toán</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{ VnPayResponse.vnp_Amount / 100|number }}đ
                  </b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="b2c-price-texts">Người mua hàng</b-col>
                  <b-col cols="12" class="h2">{{ OrderInfo.FullName }}</b-col>
                  <b-col cols="12" class="b2c-price-texts">Số điện thoại liên hệ</b-col>
                  <b-col cols="12" class="h2">{{ OrderInfo.ContactPhone }}</b-col>
                  <b-col cols="12" class="b2c-price-texts">Địa chỉ nhận hàng</b-col>
                  <b-col cols="12" class="h2">{{ OrderInfo.Addr }}</b-col>
                </b-row>

              </b-card-text>
            </b-card>
            <div class="w-100 pt-1 pb-2 text-center">
              <!-- <p class="b2c-rate-text">Bạn cảm thấy quá trình mua hàng có dễ dàng thuận tiện không?</p>
              <div class="p-3 text-center"><star-rating @rating-selected="updateRate" active-color="#FFCB45" :show-rating="false" /></div> -->
              <router-link class="btn btn-primary mr-1" to="/mua-hang">Giao dịch mới</router-link>
              <router-link class="btn btn-secondary mr-1" to="/">Về trang chủ</router-link>
            </div>
          </div>
          <b-card v-else class="p-0">
            <b-card-text>
              <b-row class="pt-1">
                <b-col cols="6" class="h2">Mã đơn hàng</b-col>
                <b-col cols="6" class="h1 text-right text-primary">{{ VnPayResponse.vnp_TxnRef }}</b-col>
              </b-row>
              <b-row class="pt-1 pb-1 border-bottom">
                <b-col cols="4" class="h2">Tình trạng</b-col>
                <b-col cols="8" class="h2 text-right text-primary">Thanh toán thất bại</b-col>
              </b-row>
              <b-row class="pt-1">
                <b-col cols="12" class="h2">Thông báo chi tiết</b-col>
                <b-col cols="12" class="b2c-price-texts">
                  {{ vnPayErrors.find(e => e.id == VnPayResponse.vnp_ResponseCode).text }}
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
          <div class="row pt-3" v-if="VnPayResponse.vnp_ResponseCode!='00'">
            <div class="col-12">
              <div class="w-100 pt-1 pb-2 text-center">
                <b-button variant="primary" @click="chonThanhToanLai()" class="mr-1">Thanh toán lại</b-button>
              </div>
            </div>
            <div class="col-12">
              <div class="w-100 pt-1 pb-2 text-center">
                <b-button @click="redirectToSupport" variant="secondary">Liên hệ hỗ trợ</b-button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3"></div>
      </div>
      <!-- Success Failure  MOMO-->
      <div v-if="MoMoResponse" class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="pb-3 pt-3 w-100 text-center border-bottom" v-if="MoMoResponse.resultCode==0">
            <h2 class="huge-text">
              <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M117.143 54.7756V60.0327C117.136 72.3552 113.146 84.3452 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7644 2.17024 68.536 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8732C24.5677 13.506 35.3873 6.97778 47.4068 4.26215C59.4262 1.54651 72.0015 2.78895 83.2572 7.80417M117.143 14.3185L60 71.5185L42.8571 54.3757"
                    stroke="#18D3C9" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <br><br>Hoàn tất đơn hàng
            </h2>
            <p class="text" v-if="OrderInfo.Phones">Chúc mừng bạn đã sở hữu SIM iTel. Quý Khách vui lòng thực hiện kích
              hoạt sim trong vòng 20 ngày kể từ ngày đặt hàng. Quá thời gian trên, iTel sẽ tiến hành thu hồi số thuê bao
              theo quy định.</p>
          </div>
          <div class="pb-3 pt-3 w-100 text-center border-bottom" v-else>
            <h2 class="huge-text">
              <svg width="127" height="127" viewBox="0 0 127 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M81.8216 44.75L45.1787 81.3929M45.1787 44.75L81.8216 81.3929M124.572 63.0714C124.572 96.8002 97.229 124.143 63.5001 124.143C29.7713 124.143 2.42871 96.8002 2.42871 63.0714C2.42871 29.3426 29.7713 2 63.5001 2C97.229 2 124.572 29.3426 124.572 63.0714Z"
                    stroke="#ED1F24" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <br><br>Thanh toán thất bại
            </h2>
          </div>
          <div v-if="MoMoResponse.resultCode==0" class="p-0">
            <b-card>
              <b-card-text>
                <b-row class="pt-1">
                  <b-col cols="6" class="h2">Mã đơn hàng
                  </b-col>
                  <b-col cols="6" class="pl-0 h1 text-right text-primary">{{ MoMoResponse.orderId }}</b-col>
                </b-row>
                <b-row class="pt-0">
                  <b-col cols="12" class="text text-primary hidden">Bạn lưu ý ghi nhớ mã đơn hàng để phục vụ kích hoạt
                    SIM
                  </b-col>
                </b-row>
                <b-row class="pt-0 pb-1 border-bottom">
                  <b-col cols="6" class="h2">Đã thanh toán</b-col>
                  <b-col cols="6" class="h1 text-right text-primary">{{ MoMoResponse.amount }}đ</b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col cols="12" class="b2c-price-texts">Người mua hàng</b-col>
                  <b-col cols="12" class="h2 text-primary">{{ OrderInfo.FullName }}</b-col>
                  <b-col cols="12" v-if="OrderInfo.Phones" class="b2c-price-texts">Số iTel đã đặt hàng</b-col>
                  <b-col cols="12" v-if="OrderInfo.Phones" class="h2 text-primary">{{ OrderInfo.Phones }}</b-col>
                  <b-col cols="12" class="b2c-price-texts">Số điện thoại liên hệ</b-col>
                  <b-col cols="12" class="h2 text-primary">{{ OrderInfo.ContactPhone }}</b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim==0" class="b2c-price-texts">Email</b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim==0" class="h2 text-primary">{{ OrderInfo.Email }}</b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim>0" class="b2c-price-texts">Địa chỉ nhận hàng</b-col>
                  <b-col cols="12" v-if="OrderInfo.hassim>0" class="h2 text-primary">{{ OrderInfo.Addr }}</b-col>
                </b-row>

              </b-card-text>
            </b-card>
            <div class="w-100 pt-1 pb-2 text-center">
              <!-- <p class="b2c-rate-text">Bạn cảm thấy quá trình mua hàng có dễ dàng thuận tiện không?</p>
              <div class="p-3 text-center"><star-rating @rating-selected="updateRate" active-color="#FFCB45" :show-rating="false" /></div> -->
              <router-link class="btn btn-primary mr-1" to="/mua-hang">Giao dịch mới</router-link>
              <router-link class="btn btn-secondary mr-1" to="/">Về trang chủ</router-link>
            </div>
          </div>

          <div class="row pt-3" v-if="MoMoResponse.resultCode!=0">
            <div class="col-12">
              <div class="w-100 pt-1 pb-2 text-center">
                <b-button @click="chonThanhToanLai()" variant="primary" class="">Thanh toán lại</b-button>
              </div>
              <div class="w-100 pt-1 pb-2 text-center">
                <b-button @click="redirectToSupport" variant="secondary">Liên hệ hỗ trợ</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal body-class="p-2" title-class="text-primary" hide-footer id="modal-cod" title="">
      <b-row>
        <b-col cols="12">
          <h3 class="huge-text text-center">Xác nhận OTP</h3>
          <p class="text text-center">Bạn vui lòng nhập mã OTP được gửi đến thuê bao {{ cart_info.ContactPhone }}</p>
          <div class="pt-2" style="display: flex;align-items: center;justify-content: center;">
            <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                separator=""
                :num-inputs="4"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-complete="handleCODNext"
            />
          </div>
          <p class="text text-center pt-2">Thời gian OTP có hiệu lực <span class="text-primary">{{ countDown }}s</span>
          </p>
          <p class="text text-center text-primary pb-3 pt-2" role="button" @click="resendOTP">Gửi lại mã OTP</p>

        </b-col>
      </b-row>
    </b-modal>
    <div class="d-none">
      <b-modal id="bv-modal-example" hide-footer>
        <div class="d-block text-center">
          <h1 class="font-weight-bold mb-2" style="color: black; font-size: 20px;">THÔNG BÁO</h1>
          <p class="mb-3">Mã ưu đãi không đúng hoặc đã hết lượt sử dụng.<br> Vui lòng kiểm tra lại!</p>
        </div>
        <div class="d-flex justify-content-center">
          <b-button class="mb-2" @click="closePopup()"
                    style="background: #ed1f24 !important; width: 100px; height: auto; border-radius: 2rem !important;">
            Đóng
          </b-button>
        </div>
      </b-modal>
    </div>
    <div class="d-none">
      <b-button id="show-btn" @click="$bvModal.show('bv-modal-create-null-price')"></b-button>
      <b-modal id="bv-modal-create-null-price" @ok="getCreateNullPrice()">
        <div class="d-block text-center">
          <h1 class="font-weight-bold mb-2" style="color: black; font-size: 20px;">THÔNG BÁO</h1>
          <p class="mb-2">Bạn có muốn thanh toán đơn hàng này không?</p>
        </div>
      </b-modal>
    </div>
    <!--detai esim  -->
    <ModalDetaiEsim :isShowEsim="isShowEsim"/>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, min, email} from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from "v-select2-component";
import OtpInput from "@bachdgvn/vue-otp-input";
import ModalDetaiEsim from '@/components/ModalDetaiEsim.vue'
import {
  BCardHeader,
  BCard,
  VBTooltip,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BPagination,
  BCardText,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  TabsPlugin,
  BTabs,
  BTab,
} from "bootstrap-vue";
import {WebCam} from "vue-web-cam";
import Vue from "vue";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";
import Fuse from "fuse.js";
import {$ctx} from "@themeConfig";
import {v4 as uuidv4} from "uuid";
import {formatNumber} from "@core/utils/utils";

let debounceHandle = null;

Vue.component("v-select", vSelect);

extend("msisdn", (value) => {
  const first4 = value.slice(0, 4);
  const first3 = value.slice(0, 3);
  if (first4 !== "8487" && first3 !== "087") {
    return false;
  } else if (
      (first4 === "8487" && value.length !== 11) ||
      (first3 === "087" && value.length !== 10)
  ) {
    return false;
  }
  return true;
});

extend("num", (value) => {
  return !/\D/.test(value);
});
extend("space", (value) => {
  return value.indexOf(" ") > 0;
});
extend("min0", (value) => {
  return value > 0;
});

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
    require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    BCardHeader,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    FeatherIcon,
    BFormCheckbox,
    BCardText,
    BPagination,
    "vue-web-cam": WebCam,
    VBTooltip,
    StarRating,
    Fuse,
    BImg,
    TabsPlugin,
    BTabs,
    BTab,
    "v-otp-input": OtpInput,
    ModalDetaiEsim
  },
  data() {
    return {
      isStatusGPMN: false,
      isStatusUrbox: false,
      isSaleEvoucher: 0,
      isSale: false,
      isOppo: false,
      isVinID: false,
      isItelSCode: false,
      isTotalSimPrice: 0,
      totalSalePrice: 0,
      totalPrice: 0,
      isStatusPromotionCode: false,
      promotionCode: '',
      countDown: 0,
      payment_text: 'Thanh toán',
      numberExceptCollapse: true,
      storeCollapse: true,
      step: 1,
      //numOfSim:1,
      //numOfeSim:0,
      NumOfPhone: 1,
      deviceId: null,
      isLoading: false,
      isLoadingFee: false,
      currentPhone: "",
      SearchString: "",
      Slug: "",
      required,
      min,
      email,
      totalRecords: 0,
      rows: [],
      paymentMethod: "VNPAY",
      isZaloMono: false,
      VnPayResponse: null,
      MoMoResponse: null,
      codResponse: null,
      cart_info: {
        deliver_work_ship: null,
        shipment_type: "home",
        data_hasSIM: true,
        ghtk_fee: 0,
        _track: "T_" + Math.random().toString(36).slice(2),
      },
      cart: [],
      serverParams: {
        columnFilters: {
          vip: true,
          normal: true,
          year: 0,
          price1: 0,
          simGroup1: 0,
          except: [],
        },
        extra: {price_sort: "desc"},
        sort: [],
        page: 1,
        pageSize: 10,
      },
      prices: [
        {id: "asc", text: "Giá tăng dần"},
        {id: "desc", text: "Giá giảm dần"},
        {id: "1", text: "Dưới 500 nghìn"},
        {id: "2", text: "500 nghìn - 1 triệu"},
        {id: "3", text: "Trên 1 triệu"},
      ],
      DataPackages: [],
      simGroups: [],
      years: [],
      deliveryTimes: [
        {id: 1, text: "Sáng: 8h00 - 11h30"},
        {id: 2, text: "Chiều: 14h00 - 17h00"},
      ],
      Cities: [],
      Districts: [],
      Wards: [],
      Hamlets: [],
      CityId: 0,
      DistrictId: 0,
      WardId: 0,
      HamletId: 0,
      VnPayResponse: {},
      Stores: [],
      vnPayErrors: [
        {id: "00", text: "Giao dịch thành công"},
        {
          id: "07",
          text: "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).",
        },
        {
          id: "09",
          text: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.",
        },
        {
          id: "10",
          text: "Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần",
        },
        {
          id: "11",
          text: "Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.",
        },
        {
          id: "12",
          text: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.",
        },
        {
          id: "13",
          text: "Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.",
        },
        {
          id: "24",
          text: "Giao dịch không thành công do: Khách hàng hủy giao dịch",
        },
        {
          id: "51",
          text: "Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.",
        },
        {
          id: "65",
          text: "Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.",
        },
        {id: "75", text: "Ngân hàng thanh toán đang bảo trì."},
        {
          id: "79",
          text: "Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch",
        },
        {
          id: "99",
          text: "Lỗi thanh toán ngân hàng, quý khách vui lòng thử lại",
        },
      ],
      OrderInfo: {},
      isExpress: false,
      isFast: true,
      hasExpressShip: false,
      expressShipInfo: {
        ahamoveFee: 0,
        lastFee: 0,
        deleveryTime: "",
      },
      needUpdateAddress: true,
      activeTab: 0,
    };
  },
  watch: {
    paymentMethod: function (i, v) {
      if (i === "ZALO" || i === "MOMO") {
        this.isZaloMono = true;
      } else {
        this.isZaloMono = false
      }
      if (this.paymentMethod == 'COD') {
        this.payment_text = 'Đặt hàng';
        this.onClickFast();
        this.cart.forEach(i => i.eSIM = false);
      } else {
        this.payment_text = 'Thanh toán'
      }
    },
    $route(to, from) {
      this.step = 1;
      this.cart_info._track = "T_" + Math.random().toString(36).slice(2);
    },
    numOfSim: function (i, v) {
      this.search();
    },
    numOfeSim: function (i, v) {
      this.search();
    },
    cart_info: {
      handler: function (o, n) {
        /*if (
          o.data_hasESIM!=n.data_hasESIM || 
          o.data_hasSIM!=n.data_hasSIM ||
          o.numOfSim!=n.numOfSim ||
          o.numOfeSim!=n.numOfeSim
        ){
          this.cartInfoChange();
        }*/
      },
      deep: true,
    },
    CityId: function (i, v) {
      if (this.needUpdateAddress) {
        this.DistrictId = 0;
      }

      if (this.CityId > 0) {
        this.loading(true);
        useJwt
            .post("getMasterData/GHTK_Districts", {
              columnFilters: {CityId: this.CityId},
              sort: [],
              page: 1,
              pageSize: 1000,
              lang: this.$l,
            })
            .then((response) => {
              this.Districts = response.data.result;
              this.loading(false);
            });
      }
    },
    DistrictId: function (i, v) {
      if (this.needUpdateAddress) {
        this.WardId = 0;
      }
      this.getShippingFee();
      if (this.DistrictId > 0) {
        this.loading(true);
        useJwt
            .post("getMasterData/GHTK_Wards", {
              columnFilters: {DistrictId: this.DistrictId},
              sort: [],
              page: 1,
              pageSize: 1000,
              lang: this.$l,
            })
            .then((response) => {
              this.Wards = response.data.result;
              this.loading(false);
            });
      }
    },
    WardId: async function (i, v) {
      this.HamletId = 0;
      if (this.WardId > 0) {
        this.loading(true);
        await useJwt.post('getMasterData/GHTK_Hamlets', {
          columnFilters: {WardId: this.WardId},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l
        }).then(response => {
          this.Hamlets = response.data.result;
          this.loading(false);
        })

      }
      this.needUpdateAddress = true;
      this.isLoadingFee = true;
      this.getShippingFee();
      await this.getGHTKFee()
    },
    isFast: async function (i, v) {
      this.needUpdateAddress = true;
      this.isLoadingFee = true;
      if (this.isFast) {
        this.getGHTKFee()
      }
    },
    "cart_info.fullName": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    "cart_info.addr": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    "cart_info.ContactPhone": {
      deep: true,
      handler(value) {
        this.handleGetShippingFee();
      },
    },
    step: function (newValue, oldValue) {
      localStorage.userTrackStep = newValue
    }
  },
  created() {
    this.getStatusPromotionCode()
    this.years.push({id: 0, text: "Tất cả ngày sinh"});
    for (var i = 0; i < 50; i++) {
      this.years.push({id: i + 1970, text: i + 1970});
    }
  },
  mounted() {
    this.countDownTimer();
    // insider_object
    window.insider_object = window.insider_object || {};
    window.insider_object.page = {
      type: "mua-sim-data",
    };

    if (sessionStorage.getItem("B2CSimDataForm")) {
      const oldData = JSON.parse(sessionStorage.getItem("B2CSimDataForm"));
      this.cart_info = {
        deliver_work_ship: null,
        ...oldData.cart_info,
        StoreId: 0,
      };
      this.CityId = oldData.CityId;
      this.DistrictId = oldData.DistrictId;
      this.WardId = oldData.WardId;
      this.paymentMethod = oldData.paymentMethod;
      this.isFast = oldData.isFast;
      this.isExpress = oldData.isExpress;
      this.hasExpressShip = oldData.hasExpressShip;
      this.activeTab = oldData.activeTab;
      this.cart = oldData.cart
      this.NumOfPhone = oldData.NumOfPhone || oldData.cart.length
      if (
          !oldData.cart_info.eSIM &&
          oldData.cart_info.shipment_type == "home"
      ) {
        this.needUpdateAddress = false;
      } else {
        this.needUpdateAddress = true;
      }
      if (
          oldData.cart_info.shipment_type == "itel" &&
          !oldData.cart_info.eSIM
      ) {
        useJwt
            .post("getMasterData/Stores1", {
              columnFilters: {},
              sort: [],
              page: 1,
              pageSize: 1000,
              lang: this.$l,
            })
            .then((response) => {
              this.Stores = response.data.result;
              this.cart_info = {
                deliver_work_ship: null,
                ...oldData.cart_info,
                StoreId: oldData.cart_info.StoreId,
              };
            })
            .catch((err) => {
              this.$toast.error(
                  "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            });
      }
    }

    if (localStorage.trackId == undefined) {
      localStorage.trackId = Math.random().toString(36).slice(2);
    }
    this.tracking(localStorage.trackId, 3);
    localStorage.userTrackStep = 3
    if (this.$route.params.step == "hoan-thanh") {
      this.step = 4;
      this.cart = [];
      sessionStorage.removeItem("B2CSimDataForm");
      this.tracking(localStorage.trackId, this.step);

      // payment with MoMo
      if (this.$route.query.orderType == "momo_wallet") {
        this.MoMoResponse = this.$route.query;
        // if MOMO code = 0 => order sucress
        if (this.MoMoResponse?.resultCode == "0") {
          window.insider_object.page = {
            type: "Confirmation",
          };
        }
        useJwt
            .post("momo-update", this.$route.query)
            .then((response) => {
              if (response.data.result.length > 0) {
                return (this.OrderInfo = response.data.result[0]);
              }
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                  "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            });
        return;
      }
      this.VnPayResponse = this.$route.query;
      //  change type insider
      if (this.VnPayResponse.vnp_ResponseCode == "00") {
        window.insider_object.page = {
          type: "Confirmation",
        };
      }
      useJwt
          .post("vnpay-update", this.$route.query)
          .then((response) => {
            console.log(response.data.result);
            if (response.data.result.length > 0) {
              this.OrderInfo = response.data.result[0];
            }
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      //tracking khi hoàn thành thanh toán
      //this.JITSU('Exit VnPay');
      /*rudderanalytics.track('B2CSimData - Exit VnPay', {
          step: this.step,
          CityId:this.CityId,
          DistrictId:this.DistrictId,
          WardId:this.WardId,
          HamletId:this.HamletId,
          VnPayResponse:this.VnPayResponse,
          cart_info: this.cart_info,
        },
        () => {console.log("in track call");}
      );*/
    } else {
      this.step = 1;
    }
    //this.cart=[];
    //tracking khi vào trang
    /*rudderanalytics.track('B2CSimData - Entry Page', {
        step: this.step,
        SearchString: this.SearchString,
        serverParams: this.serverParams,
        cart_info: this.cart_info,
      },
      () => {console.log("in track call");}
    );*/
    //this.JITSU('Entry Page');
    this.fetch();
    var t_ = this;
    EventBus.$on("CHANGE_LANG", function (payLoad) {
      t_.fetch();
    });
    if (!sessionStorage.getItem("B2CSimDataForm")) {
      this.search();
    }

  },
  computed: {
    ishowGetNumber() {
      return this.$route.name === "mua-sim-so-le";
    },
    getSource() {
      return this.$store.getters[`app/getSource`]
    }
  },
  methods: {
    closePopup() {
      this.$bvModal.hide('bv-modal-example');
      this.promotionCode = '';
    },
    handlePromotionCode() {
      this.totalSalePrice = 0;
      this.isStatusPromotionCode = false;
      this.isPromotionCode = false;
      this.isStatusUrbox = false;
      this.isItelSCode = false;
      this.isVinID = false;
      this.isOppo = false;
      this.isSale = false;
      this.isStatusGPMN = false;
      if (this.promotionCode === '') {
        this.isUrbox = false;
      }
    },
    getCreateNullPrice() {
      useJwt.post("create-null-price", {
        Phone: this.cart[0].Phone,
        PromotionCode: this.promotionCode,
        TotalPrice: this.getTotalPrice() - this.totalSalePrice,
        ShipmentFee: !this.isFullEsim() && this.isExpress && this.cart_info.shipment_type == "home"
            ? this.expressShipInfo.lastFee
            : this.getShipmentFee(),
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
        Source: this.getSource
      }).then((response) => {
        this.cart_info.vnp_TxnRef = response.data.result;
        this.tracking(localStorage.trackId, "10");
        localStorage.userTrackStep = 10
        sessionStorage.setItem(
            "B2CSimDataForm",
            JSON.stringify({
              cart_info: this.cart_info,
              CityId: this.CityId,
              DistrictId: this.DistrictId,
              WardId: this.WardId,
              paymentMethod: this.paymentMethod,
              isFast: this.isFast,
              isExpress: this.isExpress,
              hasExpressShip: this.hasExpressShip,
              activeTab: this.activeTab,
              storeCollapse: this.storeCollapse,
              cart: this.cart,
              NumOfPhone: this.NumOfPhone
            })
        );
        var c = JSON.parse(JSON.stringify(this.cart_info));
        var p = [];
        this.cart.forEach((item) => {
          p.push(item.Phone);
        });
        c.Phones = p.join(",");
        c.TotalPrice = this.getTotalPrice() - this.totalSalePrice
        try {
          if (this.activeTab === 1) {
            c.FullAddress = this.Stores.find(el => el.Id == this.cart_info.StoreId).Name
          } else {
            c.FullAddress = this.cart_info.addr + ", " + this.Wards.find(i => i.id == this.WardId).text + " - " + this.Districts.find(i => i.id == this.DistrictId).text + " - " + this.Cities.find(i => i.id == this.CityId).text;
          }
        } catch (err) {
        }
        this.loading(false);
        document.location = '/mua-hang/hoan-thanh/COD?cart_info=' + JSON.stringify(c);
      }).catch((err) => {
        this.loading(false);
        this.$toast.error("Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!", {icon: true, closeButton: "button"});
      });
    },
    getStatusPromotionCode() {
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      let isPromotionCode = true;
      if (this.promotionCode?.length > 0) {
        useJwt.get(`check-status-promotion-code/${promotionCode}`).then((response) => {
          if (response.data.code == 200) {
              this.isPromotionCode = true;
              this.isStatusPromotionCode = true;
            }
            if(response.data.result){
              this.isUrbox = response.data.result.isUrbox;
              this.isSaleEvoucher = response.data.result.value;
              this.isStatusUrbox = true;
              this.isStatusPromotionCode = false;
            }
          this.isSale = true;
          this.getSalePrice();
          if (this.promotionCode.trim().toUpperCase().startsWith('ITELS')) {
            this.isItelSCode = true;
            this.isStatusPromotionCode = false;
          } else if (this.promotionCode.trim().toUpperCase().startsWith('VINID')) {
            this.isVinID = true;
            this.isStatusPromotionCode = false;
          } else if (this.promotionCode.trim().toUpperCase() === 'OPPO38') {
            this.isOppo = true;
            this.isStatusPromotionCode = false;
          } else if (this.promotionCode.trim().toUpperCase().startsWith('GPMN50')) {
            this.isStatusGPMN = true;
            this.isStatusPromotionCode = false;
          }
          return true;
        })
            .catch((err) => {
              if (err.response.status == 404) {
                const res = this.$bvModal.show('bv-modal-example')
                if (!res) {
                  isPromotionCode = false
                }
              }
            })
        if (!isPromotionCode) {
          return false
        }

      }
    },
    async getGHTKFee() {
      this.isLoadingFee = true;
      await useJwt
          .get(
              "ghtk/fee?province=" +
              this.CityId +
              "&district=" +
              this.DistrictId +
              "&ward=" +
              this.WardId
          )
          .then((response) => {
            this.cart_info = {
              ...this.cart_info,
              ghtk_fee: response.data.result.fee.fee,
            };
            this.isLoadingFee = false;
          })
          .catch((err) => {
            this.cart_info = {
              ...this.cart_info,
              ghtk_fee: 0,
            };
            this.isLoadingFee = false;
          });
    },
    countDownTimer() {
      setTimeout(() => {
        this.countDown = this.countDown > 1 ? this.countDown - 1 : 0
        this.countDownTimer()
      }, 1000)
    },
    resendOTP() {
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      useJwt.post("otp_cod/" + this.cart_info.ContactPhone, {
        Phone: this.cart_info.ContactPhone,
        PromotionCode: promotionCode,
        TotalPrice: this.getTotalPrice() - this.totalSalePrice,
        ShipmentFee: !this.isFullEsim() && this.isExpress && this.cart_info.shipment_type == "home"
            ? this.expressShipInfo.lastFee
            : this.getShipmentFee(),
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
      }).then((response) => {
        this.$toast.success("Vui lòng kiểm tra tin nhắn!",
            {icon: true, closeButton: "button"}
        );
        this.countDown = 60;
        this.loading(false);
      }).catch((err) => {
        this.loading(false);
        this.$toast.error(err.response.data.message || "Có lỗi gửi OTP, Quý khách vui lòng thử lại!",
            {icon: true, closeButton: "button"}
        );
      });
    },
    handleCODNext(otp_) {
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      console.log('OTP completed: ', otp_);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      this.cart_info.OTP = otp_;
      useJwt.post("create-cod-order", {
        Phone: this.cart[0].Phone,
        PromotionCode: promotionCode,
        TotalPrice: this.getTotalPrice() - this.totalSalePrice,
        ShipmentFee: !this.isFullEsim() && this.isExpress && this.cart_info.shipment_type == "home"
            ? this.expressShipInfo.lastFee
            : this.getShipmentFee(),
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
        Source: this.getSource
      }).then((response) => {
        this.cart_info.vnp_TxnRef = response.data.result;
        this.tracking(localStorage.trackId, "10");
        localStorage.userTrackStep = 10
        sessionStorage.setItem(
            "B2CSimDataForm",
            JSON.stringify({
              cart_info: this.cart_info,
              CityId: this.CityId,
              DistrictId: this.DistrictId,
              WardId: this.WardId,
              paymentMethod: this.paymentMethod,
              isFast: this.isFast,
              isExpress: this.isExpress,
              hasExpressShip: this.hasExpressShip,
              activeTab: this.activeTab,
              storeCollapse: this.storeCollapse,
              cart: this.cart,
              NumOfPhone: this.NumOfPhone
            })
        );
        // document.location = response.data.result.url;
        console.log("COD --->", response.data.result);
        var c = JSON.parse(JSON.stringify(this.cart_info));
        var p = [];
        this.cart.forEach((item) => {
          p.push(item.Phone);
        });
        c.Phones = p.join(",");
        c.TotalPrice = this.getTotalPrice() - this.totalSalePrice
        try {
          if (this.activeTab === 1) {
            c.FullAddress = this.Stores.find(el => el.Id == this.cart_info.StoreId).Name
          } else {
            c.FullAddress = this.cart_info.addr + ", " + this.Wards.find(i => i.id == this.WardId).text + " - " + this.Districts.find(i => i.id == this.DistrictId).text + " - " + this.Cities.find(i => i.id == this.CityId).text;
          }
        } catch (err) {
        }
        this.$bvModal.hide("modal-cod");
        this.loading(false);
        document.location = '/mua-hang/hoan-thanh/COD?cart_info=' + JSON.stringify(c);
      })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 400) {
              this.$bvModal.show("modal-dup");
            } else if (err.response.status == 403) {
              this.$toast.error(
                  "Vui lòng kiểm tra lại OTP, OTP chỉ có hiệu lực trong 1 phút!",
                  {icon: true, closeButton: "button"}
              );
            } else {
              this.$toast.error(
                  "Có lỗi trong khi thực hiện đặt hàng, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            }
            this.loading(false);
          });
    },
    formatNumber(x) {
      return formatNumber(x);
    },
    handleGetShippingFee() {
      if (debounceHandle) {
        clearTimeout(debounceHandle);
      }

      debounceHandle = setTimeout(() => {
        this.getShippingFee();
      }, 2000);
    },
    f087(value) {
      return value.substr(0, 4) + " " + value.substr(4);
    },
    getTotalWithoutEsim() {
      var i = 0;
      // console.log(this.cart);
      this.cart.forEach((m) => {
        i = m.Price + m.PackPrice + m.SimPrice + i;
      });
      return i;
    },
    changeEsim(e) {
      if (e) {
        this.isShowEsim = !this.isShowEsim
      }
      this.isLoading = true;
      if (this.cart_info.eSIM) {
        this.cart.forEach((i) => (i.eSIM = true));
      } else {
        this.cart.forEach((i) => (i.eSIM = false));
      }
      if (!sessionStorage.getItem("B2CSimDataForm")) {
        this.paymentMethod = 'VNPAY';
      }
      this.isLoading = false;
    },
    redirectToSupport() {
      this.$router.push({
        path: this.$t("Route.Support") + this.$t("Route.ContactUs"),
      });
    },

    cartInfoChange(type) {
      return;
      /*if (type==1){
        if (!this.cart_info.data_hasESIM){
          this.numOfeSim=0;
        }else{
          this.numOfeSim=1;
        }
        if (!this.cart_info.data_hasSIM){
          this.numOfSim=0;
        }else{
          this.numOfSim=1;
        }
        if (!this.cart_info.data_hasESIM && !this.cart_info.data_hasSIM){
          this.cart_info.data_hasSIM=true;
          this.numOfSim=1;
        }
      }
      if (type==2){
        if (this.numOfeSim==0){
          this.cart_info.data_hasESIM=false;
        }else{
          this.cart_info.data_hasESIM=true;
        }
        if (this.numOfSim==0){
          this.cart_info.data_hasSIM=false;
        }else{
          this.cart_info.data_hasSIM=true;
        }
      }
      this.search();*/
    },
    tracking(id, step) {
      const parsedParams = this.$route.query;
      this.$route.hash
          .split("&")
          .map((part) => part.replace(/^#/, ""))
          .forEach((param) => {
            const parts = param.split("=");
            parsedParams[parts[0]] = parts[1];
          });
      useJwt
          .post("tracking/" + id + "/" + step, {
            SimData: true,
            cart_info: this.cart_info,
            card: this.cart,
            query: parsedParams,
          })
          .then((response) => {
            //console.log(response.data.result)
          });
    },
    /*JITSU(type_){
      jitsu.track('B2CSimData', {
        step: this.step,
        currentPhone: this.currentPhone,
        SearchString: this.SearchString,
        totalRecords: this.totalRecords,
        cart_info: this.cart_info,
        cart: this.cart,
        serverParams: this.serverParams,
        CityId:this.CityId,
        DistrictId:this.DistrictId,
        WardId:this.WardId,
        HamletId:this.HamletId,
        VnPayResponse:this.VnPayResponse,
        OrderInfo:this.OrderInfo,
        trackingType: type_
      });
      rudderanalytics.track('B2CSimData - '+type_, {
          step: this.step,
          currentPhone: this.currentPhone,
          SearchString: this.SearchString,
          totalRecords: this.totalRecords,
          cart_info: this.cart_info,
          cart: this.cart,
          serverParams: this.serverParams,
          CityId:this.CityId,
          DistrictId:this.DistrictId,
          WardId:this.WardId,
          HamletId:this.HamletId,
          VnPayResponse:this.VnPayResponse,
          OrderInfo:this.OrderInfo,
          trackingType: type_
        },
        () => {console.log("in track call");}
      );
    },*/
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["text", "text1"],
        shouldSort: true,
      });
      return search.length
          ? fuse.search(search).map(({item}) => item)
          : fuse.list;
    },
    async changeNumOfPhone(i) {
      this.isLoading = true;
      if (this.NumOfPhone + i > 0) {
        this.NumOfPhone = this.NumOfPhone + i;
      }
      await this.search();
      this.isLoading = false;
    },
    /*changeNumOfESim(i){
      this.isLoading=true;
      if (this.numOfSim+this.numOfeSim+i>0 && this.numOfeSim+i>=0){
        this.numOfeSim = this.numOfeSim+i;
      }
      
      this.cartInfoChange(2);
      this.isLoading=false;
    },*/
    /*changeNumOfSim(i){
      this.isLoading=true;
      if (this.numOfSim+this.numOfeSim+i>0 && this.numOfSim+i>=0){
        this.numOfSim = this.numOfSim+i;
      }
      this.cartInfoChange(2);
      this.isLoading=false;
    },*/
    updateRate: function (rating) {
      this.loading(true);
      const OrderId = this.VnPayResponse?.vnp_TxnRef
          ? this.VnPayResponse?.vnp_TxnRef
          : this.MoMoResponse.orderId;
      localStorage.currentOrderId = OrderId
      useJwt
          .put("update-rate", {
            OrderId: OrderId,
            rate: rating,
          })
          .then((response) => {
            this.loading(false);
          })
          .catch((err) => {
            this.loading(false);
          });
    },
    getShippingFee() {
      if (
          !(this.CityId && this.DistrictId && this.WardId && this.cart_info.addr)
      ) {
        this.expressShipInfo = {
          ahamoveFee: 0,
          lastFee: 0,
          deleveryTime: "",
        };
        return;
      }
      let fullAddress = this.cart_info.addr || "";
      if (this.WardId && this.Wards.find((el) => el.id == this.WardId)) {
        fullAddress += `, ${
            this.Wards.find((el) => el.id == this.WardId).text
        }`;
      }
      if (
          this.DistrictId &&
          this.Districts.find((el) => el.id == this.DistrictId)
      ) {
        fullAddress += `, ${
            this.Districts.find((el) => el.id == this.DistrictId).text
        }`;
      }
      if (this.CityId && this.Cities.find((el) => el.id == this.CityId)) {
        fullAddress += `, ${
            this.Cities.find((el) => el.id == this.CityId).text
        }`;
      }
      useJwt
          .post("get_fee_and_delivery_time", {
            city_id: this.CityId,
            addr: fullAddress,
            FullName: this.cart_info.fullName,
            ContactPhone: this.cart_info.ContactPhone,
          })
          .then((response) => {
            this.expressShipInfo = {
              ahamoveFee: response?.data?.result?.ahamove_fee || 0,
              lastFee: response?.data?.result?.last_fee || 0,
              deleveryTime: response?.data?.result?.delivery_time || "",
            };
          })
          .catch((err) => {
            this.$toast.error(
                err?.response?.data?.message ||
                "Có lỗi trong khi thực hiện tra cứu, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );

            this.expressShipInfo = {
              ahamoveFee: 0,
              lastFee: 0,
              deleveryTime: "",
            };
          });
    },
    onCityChange() {
      this.loading(true);
      this.getShippingFee();
      useJwt
          .get(`is_fast/${this.CityId}`)
          .then((response) => {
            this.hasExpressShip = !!response?.data?.result;
            if (!this.hasExpressShip) {
              this.isExpress = false;
              this.isFast = true;
            }
            this.loading(false);
          })
          .catch((err) => {
            this.hasExpressShip = false;
            this.isExpress = false;
            this.isFast = true;
            this.loading(false);
          });
    },
    onClickExpress() {
      if (!this.isExpress) {
        this.isExpress = true;
        this.isFast = false;
      }
    },
    onClickFast() {
      if (!this.isFast) {
        this.isFast = true;
        this.isExpress = false;
      }
    },
    onShipmentTabChange(e) {
      this.change_shipment(e == 0 ? "home" : "itel");
    },
    change_shipment(e) {
      this.isLoading = true;
      this.cart_info.shipment_type = e;
      this.storeCollapse = this.cart_info.shipment_type == "home";
      this.isLoading = false;
    },
    isFullEsim() {
      return this.cart.find((m) => !m.eSIM) == undefined
    },
    getShipmentFee() {
      if (
          this.cart_info.shipment_type == "home" &&
          this.cart.find((m) => !m.eSIM) != undefined
      ) {
        //Nếu đơn CoD thì 25000
        if (this.paymentMethod == 'COD') {
          return 25000;
        }
        return 15000;
      } else {
        return 0;
      }
    },
    exception(value) {
      var e = this.serverParams.columnFilters.except.find((i) => i == value);
      if (e != undefined) {
        this.serverParams.columnFilters.except =
            this.serverParams.columnFilters.except.filter((i) => i != value);
      } else {
        this.serverParams.columnFilters.except.push(value);
      }
    },
    bindingTotalPrice() {
      this.totalPrice = this.getTotalPrice() - this.totalSalePrice;
    },
    getSalePrice() {
      this.promotionCode = this.promotionCode.trim();
      if (this.promotionCode.toUpperCase().startsWith('ITELS') || this.promotionCode.toUpperCase().startsWith('VINID')) {
        let a = 0;
        a = parseInt(this.promotionCode.substring(5));
        if (a > 0 && a <= 500 && this.promotionCode.toUpperCase().startsWith('ITELS') && (this.promotionCode.length === 8)) {
          this.totalSalePrice = 38100;
        } else if (a > 500 && a <= 2500 && this.promotionCode.toUpperCase().startsWith('ITELS')) {
          this.totalSalePrice = 77000;
        } else if (a >= 1 && a <= 2000 && this.promotionCode.toUpperCase().startsWith('VINID') && (this.promotionCode.length === 9)) {
          this.totalSalePrice = 77000;
        }
      } else {
        this.totalSalePrice = 0;
        if(this.isUrbox) {
          this.totalSalePrice = this.isSaleEvoucher;
        }
        this.handleTotalPrice();
        if (this.isPromotionCode && !this.isUrbox) {
          if (this.promotionCode.toUpperCase() === 'OPPO38') {
            this.totalSalePrice = 38100;
          } else if (this.promotionCode.toUpperCase() === 'OPPO77') {
            this.totalSalePrice = 77000;
          } else if (this.promotionCode.toUpperCase() === 'GPMN50') {
            this.cart.forEach((i)=>{
              this.totalSalePrice+=50000
            })
          } else {
            if (this.isTotalSimPrice >= 100000) {
              this.totalSalePrice = 30000;
            } else {
              this.totalSalePrice = this.isTotalSimPrice * 30 / 100
            }
          }
        }
      }
    },
    handleTotalPrice() {
      this.isTotalSimPrice = 0;
      this.cart.forEach((i) => {
        if (i.eSIM) {
          this.isTotalSimPrice += i.EsimPrice + i.Price;
        } else {
          this.isTotalSimPrice += i.SimPrice + i.Price;
        }
      });
      var a = 0
        this.cart.forEach((i) => {
          if (this.DataPackages.find((m) => m.Name == i.Pack) != undefined) {
          a += this.DataPackages.find((m) => m.Name == i.Pack).Price;
        }
      });
      if ((this.isTotalSimPrice+a) - this.isSaleEvoucher < 0) {
        this.totalSalePrice = (this.isTotalSimPrice+a);
      }
    },
    getTotalPrice() {
      var n = 0;
      this.cart.forEach((i) => {
        n += i.TraThang ? i.TotalPrice : i.Price;
        if (i.eSIM) {
          n += i.EsimPrice;
        } else {
          n += i.SimPrice;
        }
        if (this.DataPackages.find((m) => m.Name == i.Pack) != undefined) {
          n += this.DataPackages.find((m) => m.Name == i.Pack).Price;
        }
      });
      return (
          n +
          (!this.isFullEsim() && this.isExpress && this.cart_info.shipment_type == "home"
              ? this.expressShipInfo.lastFee
              : this.getShipmentFee())
      );
    },
    addToCart(i) {
      this.cart.push(JSON.parse(JSON.stringify(i)));
      this.step = 2;
      //console.log(i);
      this.getSalePrice();
      this.currentPhone = i.Phone;
    },
    getPrices() {
      var x = JSON.parse(JSON.stringify(this.prices));
      x.splice(0, 0, {id: 0, text: "Tất cả giá"});
      return x;
    },

    chonThanhToanLai() {
      if (this.$route.query.orderType == "momo_wallet") {
        return this.thanhtoanlaiMoMo();
      } else {
        return this.thanhtoanlai();
      }
    },

    // Thanh toán lại bằng Ví MOMO
    thanhtoanlaiMoMo() {
      const urlPay = "get-momo-url-again/" + this.$route.query.orderId;
      this.loading(true);
      useJwt
          .get(urlPay)
          .then((response) => {
            if (response.data.result.url) {
              this.tracking(localStorage.trackId, "11");
              localStorage.userTrackStep = 11
              document.location = response.data.result.url;
              return;
            }
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
    },
    // Thanh toán lại bằng Ví VNPAY
    thanhtoanlai() {
      const urlPay = "get-vnpay-url-again/" + this.VnPayResponse.vnp_TxnRef;
      this.loading(true);
      useJwt
          .get(urlPay)
          .then((response) => {
            var urlParams = new URLSearchParams(response.data.result.url);
            this.cart_info.vnp_TxnRef = urlParams.get("vnp_TxnRef");
            this.cart_info.old_vnp_TxnRef = this.VnPayResponse.vnp_TxnRef;
            //console.log(response.data.result);
            this.tracking(localStorage.trackId, "11");
            localStorage.userTrackStep = 11
            console.log(this.cart_info);
            document.location = response.data.result.url;
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
    },
    getSimGroups() {
      var x = JSON.parse(JSON.stringify(this.simGroups));
      x.splice(0, 0, {id: 0, text: "Tất cả loại SIM"});
      return x;
    },
    selectStore(id) {
      this.isLoading = true;
      this.cart_info.StoreId = id.Id;
      this.isLoading = false;
      this.storeCollapse = true;
    },
    filterReset(msg) {
      this.isLoading = true;
      this.serverParams.columnFilters.simGroup = [];
      this.simGroups.forEach((i) => {
        this.serverParams.columnFilters.simGroup.push(i.id);
      });
      this.serverParams.columnFilters.price = [];
      for (var i = 0; i < this.prices.length; i++) {
        this.serverParams.columnFilters.price.push(i + 1);
      }
      this.serverParams.columnFilters.vip = true;
      this.serverParams.columnFilters.normal = true;
      this.serverParams.columnFilters.price1 = 0;
      this.serverParams.columnFilters.simGroup1 = 0;
      this.serverParams.columnFilters.year = 0;
      this.isLoading = false;
      this.serverParams.columnFilters.except = [];
      if (msg)
        this.$toast.info("Đã reset bộ lọc!", {
          icon: true,
          closeButton: "button",
        });
    },
    search() {
      //console.log(this.serverParams);
      this.loading(true);
      this.isLoading = true;
      const url =
          this.$route.name == "mua-sim-so-le"
              ? `sim_for_retail/${this.$route.params.id}`
              : `sim_search_sim_data/${this.NumOfPhone}`;
      useJwt
          .get(url)
          .then((response) => {
            this.cart = response.data.result;
            // Push data to Insider
            window.insider_object.product = {
              id: `sim-data-${this.cart[0]?.Phone}`,
              name: this.cart[0]?.Pack,
              currency: "VND",
              unit_price: this.getTotalPrice() - this.totalSalePrice,
              unit_sale_price: 0,
              url: window.location.href,
              product_image_url: `https://itel.vn/san-pham/${this.cart[0]?.Phone}`,
              custom: {
                product_link: `https://itel.vn/san-pham/${this.cart[0]?.Phone}`,
                card: this.cart,
              },
            };
            this.loading(false);
            try {
              this.changeEsim();

              if (sessionStorage.getItem("B2CSimDataForm")) {
                sessionStorage.removeItem("B2CSimDataForm")
              }
            } catch (err) {
            }
            this.isLoading = false;
            //Tracking khi tìm số
            //this.JITSU('Sim Search (random)');
            /*rudderanalytics.track('B2CSimData - Sim Search random', {
              step: this.step,
              currentPhone: this.currentPhone,
              SearchString: this.SearchString,
              totalRecords: this.totalRecords,
              cart_info: this.cart_info,
              cart: this.cart,
              serverParams: this.serverParams
            },
            () => {console.log("in track call");}
          );*/
            this.getSalePrice();
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi lấy SIM Data, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
    },

    onPayment() {
      if (!this.isFullEsim() && this.isExpress && this.cart_info.shipment_type == 'home') {
        this.cart_info.ghtk_fee = this.expressShipInfo.ahamoveFee
      }
      if(this.paymentMethod === "ZALO" || this.paymentMethod === "MOMO" || this.paymentMethod === "VNPAY" || this.paymentMethod === "BANK" || this.paymentMethod === "INTCARD") {
        if (this.isStatusUrbox && this.getTotalPrice() - this.totalSalePrice <=0) {
          this.$bvModal.show('bv-modal-create-null-price');
          return;
        }
      }
      switch (this.paymentMethod) {
        case "VNPAY":
          return this.thanhtoan("VNPAYQR");
        case "BANK":
          return this.thanhtoan("VNBANK");
        case "INTCARD":
          return this.thanhtoan("INTCARD");
        case "MOMO":
          return this.thanhtoanMOMO();
        case "ZALO":
          return this.thanhtoanZALO();
        case "COD":
          return this.thanhtoanCOD();
      }
    },

    async thanhtoanCOD() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      let isPromotionCode = true;
      if (this.promotionCode?.length > 0) {
        await useJwt.get(`check-status-promotion-code/${promotionCode}`).then((response) => {
        })
            .catch(async (err) => {
              if (err.response.status == 404) {
                const res = await this.$bvModal.show('bv-modal-example')
                if (!res) {
                  isPromotionCode = false
                }
              }
            })
        if (!isPromotionCode) {
          return false
        }
      }
      this.loading(true);
      await useJwt.post("otp_cod/" + this.cart_info.ContactPhone, {
        Phone: this.cart_info.ContactPhone,
        TotalPrice: this.getTotalPrice() - this.totalSalePrice,
        PromotionCode: promotionCode,
        ShipmentFee: !this.isFullEsim() && this.isExpress && this.cart_info.shipment_type == "home"
            ? this.expressShipInfo.lastFee
            : this.getShipmentFee(),
        vnp_Bill_Mobile: this.cart_info.ContactPhone,
        fullName: this.cart_info.fullName,
        cart_info: this.cart_info,
        cart: this.cart,
      }).then((response) => {
        this.loading(false);
        this.$bvModal.show('modal-cod');
        this.countDown = 60;
      }).catch((err) => {
        this.loading(false);
        this.$toast.error(err.response.data.message || "Có lỗi gửi OTP, Quý khách vui lòng thử lại!",
            {icon: true, closeButton: "button"}
        );
      });
    },

    async thanhtoanZALO() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }
      console.log(this.cart_info);
      //return;
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      // add Inserdate
      window.insider_object.user = {
        uuid: uuidv4(),
        name: this.cart_info?.fullName,
        surname: this.cart_info?.fullName,
        username: this.cart_info?.fullName,
        email: this.cart_info?.email,
        phone_number: this.cart_info.ContactPhone,
        language: "vi",
        returning: true,
        list_id: [1, 3, 5],
      };
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      let isPromotionCode = true;
      if (this.promotionCode?.length > 0) {
        await useJwt.get(`check-status-promotion-code/${promotionCode}`).then((response) => {
        })
            .catch(async (err) => {
              if (err.response.status == 404) {
                const res = await this.$bvModal.show('bv-modal-example')
                if (!res) {
                  isPromotionCode = false
                }
              }
            })
        if (!isPromotionCode) {
          return false
        }
      }
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      useJwt
          .post("get-zalopay-url", {
            Phone: this.cart[0].Phone,
            PromotionCode: promotionCode,
            TotalPrice: this.getTotalPrice() - this.totalSalePrice,
            ShipmentFee: !this.isFullEsim() && this.isExpress && this.cart_info.shipment_type == "home"
                ? this.expressShipInfo.lastFee
                : this.getShipmentFee(),
            vnp_Bill_Mobile: this.cart_info.ContactPhone,
            fullName: this.cart_info.fullName,
            cart_info: this.cart_info,
            cart: this.cart,
            Source: this.getSource
          })
          .then((response) => {
            this.cart_info.vnp_TxnRef = response.data.result.vnp_TxnRef;
            this.tracking(localStorage.trackId, "10");
            localStorage.userTrackStep = 10
            sessionStorage.setItem(
                "B2CSimDataForm",
                JSON.stringify({
                  cart_info: this.cart_info,
                  CityId: this.CityId,
                  DistrictId: this.DistrictId,
                  WardId: this.WardId,
                  paymentMethod: this.paymentMethod,
                  isFast: this.isFast,
                  isExpress: this.isExpress,
                  hasExpressShip: this.hasExpressShip,
                  activeTab: this.activeTab,
                  storeCollapse: this.storeCollapse,
                  cart: this.cart,
                  NumOfPhone: this.NumOfPhone
                })
            );
            document.location = response.data.result.url;
            this.loading(false);
          })
          .catch((err) => {
            console.log(err.response.status);
            if (err.response.status == 400) {
              this.$bvModal.show("modal-dup");
            } else {
              this.$toast.error(
                  "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            }
            this.loading(false);
          });
    },

    async thanhtoanMOMO() {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      if (this.cart.length == 0) {
        this.$toast.warning("Vui lòng chọn số trước khi thanh toán", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }
      console.log(this.cart_info);
      //return;
      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }

      // add Inserdate
      window.insider_object.user = {
        uuid: uuidv4(),
        name: this.cart_info?.fullName,
        surname: this.cart_info?.fullName,
        username: this.cart_info?.fullName,
        email: this.cart_info?.email,
        phone_number: this.cart_info.ContactPhone,
        language: "vi",
        returning: true,
        list_id: [1, 3, 5],
      };
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      let isPromotionCode = true;
      if (this.promotionCode?.length > 0) {
        await useJwt.get(`check-status-promotion-code/${promotionCode}`).then((response) => {
        })
            .catch(async (err) => {
              if (err.response.status == 404) {
                const res = await this.$bvModal.show('bv-modal-example')
                if (!res) {
                  isPromotionCode = false
                }
              }
            })
        if (!isPromotionCode) {
          return false
        }
      }
      this.loading(true);
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      useJwt
          .post("get-momo-url", {
            Phone: this.cart[0].Phone,
            PromotionCode: promotionCode,
            TotalPrice: this.getTotalPrice() - this.totalSalePrice,
            ShipmentFee:
                !this.isFullEsim() && this.isExpress && this.cart_info.shipment_type == "home"
                    ? this.expressShipInfo.lastFee
                    : this.getShipmentFee(),
            vnp_Bill_Mobile: this.cart_info.ContactPhone,
            fullName: this.cart_info.fullName,
            cart_info: this.cart_info,
            cart: this.cart,
            Source: this.getSource
          })
          .then((response) => {
            this.cart_info.vnp_TxnRef = response.data.result.vnp_TxnRef;
            this.tracking(localStorage.trackId, "10");
            localStorage.userTrackStep = 10
            sessionStorage.setItem(
                "B2CSimDataForm",
                JSON.stringify({
                  cart_info: this.cart_info,
                  CityId: this.CityId,
                  DistrictId: this.DistrictId,
                  WardId: this.WardId,
                  paymentMethod: this.paymentMethod,
                  isFast: this.isFast,
                  isExpress: this.isExpress,
                  hasExpressShip: this.hasExpressShip,
                  activeTab: this.activeTab,
                  storeCollapse: this.storeCollapse,
                  cart: this.cart,
                  NumOfPhone: this.NumOfPhone
                })
            );
            document.location = response.data.result.url;
            this.loading(false);
          })
          .catch((err) => {
            console.log(err.response.status);
            if (err.response.status == 400) {
              this.$bvModal.show("modal-dup");
            } else {
              this.$toast.error(
                  "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                  {icon: true, closeButton: "button"}
              );
            }
            this.loading(false);
          });
    },

    async thanhtoan(vnp_BankCode) {
      if (this.cart_info.fullName) {
        this.cart_info.fullName = this.cart_info.fullName.trim();
      }
      if (this.cart_info.addr) {
        this.cart_info.addr = this.cart_info.addr.trim();
      }
      if (this.cart_info.email) {
        this.cart_info.email = this.cart_info.email.trim();
      }
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      if (!validate) {
        this.$toast.warning(
            "Vui lòng nhập đủ thông tin Họ tên, Điện thoại liên lạc, Email (nếu sử dụng eSIM)",
            {icon: true, closeButton: "button"}
        );
        return;
      }

      if (this.cart.find((i) => !i.eSIM) != undefined) {
        if (
            this.cart_info.shipment_type == "itel" &&
            (this.cart_info.StoreId == undefined || this.cart_info.StoreId == 0)
        ) {
          this.$toast.warning("Vui lòng chọn phòng giao dịch Itel", {
            icon: true,
            closeButton: "button",
          });
          return;
        }
      }
      const promotionCode = this.promotionCode?.length > 0 ? this.promotionCode.trim() : null;
      let isPromotionCode = true;
      if (this.promotionCode?.length > 0) {
        await useJwt.get(`check-status-promotion-code/${promotionCode}`).then((response) => {
        })
            .catch(async (err) => {
              if (err.response.status == 404) {
                const res = await this.$bvModal.show('bv-modal-example')
                if (!res) {
                  isPromotionCode = false
                }
              }
            })
        if (!isPromotionCode) {
          return false
        }
      }
      this.cart_info.CityId = this.CityId;
      this.cart_info.DistrictId = this.DistrictId;
      this.cart_info.WardId = this.WardId;
      this.cart_info.HamletId = this.HamletId;
      this.cart_info.IsSimData = 1;
      this.cart_info.IsFast = this.isExpress ? 1 : 0;
      this.loading(true);

      // add Inserder
      window.insider_object.user = {
        uuid: uuidv4(),
        name: this.cart_info?.fullName,
        surname: this.cart_info?.fullName,
        username: this.cart_info?.fullName,
        email: this.cart_info?.email,
        phone_number: this.cart_info.ContactPhone,
        language: "vi",
        returning: true,

      };
      useJwt
          .post("get-vnpay-url", {
            Phone: this.cart[0].Phone,
            PromotionCode: promotionCode,
            TotalPrice: this.getTotalPrice() - this.totalSalePrice,
            ShipmentFee:
                !this.isFullEsim() && this.isExpress && this.cart_info.shipment_type == "home"
                    ? this.expressShipInfo.lastFee
                    : this.getShipmentFee(),
            vnp_Bill_Mobile: this.cart_info.ContactPhone,
            fullName: this.cart_info.fullName,
            cart_info: this.cart_info,
            cart: this.cart,
            vnp_BankCode: vnp_BankCode,
            Source: this.getSource
          })
          .then((response) => {
            var urlParams = new URLSearchParams(response.data.result.url);
            this.cart_info.vnp_TxnRef = urlParams.get("vnp_TxnRef");
            this.tracking(localStorage.trackId, "10");
            localStorage.userTrackStep = 10

            sessionStorage.setItem(
                "B2CSimDataForm",
                JSON.stringify({
                  cart_info: this.cart_info,
                  CityId: this.CityId,
                  DistrictId: this.DistrictId,
                  WardId: this.WardId,
                  paymentMethod: this.paymentMethod,
                  isFast: this.isFast,
                  isExpress: this.isExpress,
                  hasExpressShip: this.hasExpressShip,
                  activeTab: this.activeTab,
                  storeCollapse: this.storeCollapse,
                  cart: this.cart,
                  NumOfPhone: this.NumOfPhone
                })
            );

            document.location = response.data.result.url;
            this.loading(false);
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
    },
    Img(pic) {
      return images[pic];
    },
    async fetch() {
      this.loading(true);
      await useJwt
          .post("getMasterData/SimConfigGroup", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.simGroups = response.data.result;
            this.simGroups.forEach((i) => {
              i.text = i.group_name;
            });
            this.filterReset();
            this.loading(false);
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      await useJwt
          .post("getMasterData/AllDataPack", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.DataPackages = response.data.result.DataPackages;
            this.loading(false);
          })
          .catch((err) => {
            this.loading(false);
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      await useJwt
          .post("getMasterData/GHTK_Cities", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Cities = response.data.result;
          })
          .catch((err) => {
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
      await useJwt
          .post("getMasterData/Stores1", {
            columnFilters: {},
            sort: [],
            page: 1,
            pageSize: 1000,
            lang: this.$l,
          })
          .then((response) => {
            this.Stores = response.data.result;
          })
          .catch((err) => {
            this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
                {icon: true, closeButton: "button"}
            );
          });
    },
    preventEnterTextCharacter(event) {
      const regex = /[a-zA-Z]/;
      var key = String.fromCharCode(
          !event.charCode ? event.which : event.charCode
      );
      if (regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    ChangePaymentMethod(method) {
      this.paymentMethod = method;
    }
  },
};
</script>
<style lang="scss">
.arrow-icon-end {
  background-image: url("../assets/images/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: 10px 18px;
  background-position: 95% 50%;
  padding: 11.5px !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.check-box-policy {
  display: flex;
  align-items: center;

  .custom-control-label {
    padding-left: 15px !important;
  }
}

.disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.6;
}

.shipping-nav {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 !important;
}

.fs-sp .shipping-nav {
  overflow-y: visible;
  overflow-x: scroll;
}

.fs-sp .shipping-nav::-webkit-scrollbar {
  display: none;
}

.shipping-nav li a {
  white-space: nowrap;
}
</style>
<style>
.modal-header .close {
  display: none;
}

.modal .modal-content {
  width: 360px;
  border-radius: 10px;
}

.table-step {
  width: 100%;
}

.table-step td {
  width: 25%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 0.5rem;
}

.b2c-bg-top {
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}

.b2c-top-left::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}

.b2c-top-left {
  height: 44px;
  padding: 8px 14px;
  width: 20%;
}

.b2c-top-right {
  width: 20%;
}

.b2c-top-right1::before {
  width: 20%;
}

.b2c-top-center {
  width: 80%;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-top: 7px;
}

.b2c-steps {
  width: 100%;
  height: 42px;
  background: #d71a1f;
}

.b2c-text-solo {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
}

.b2c-bg {
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}

.b2c-number-087 {
  width: 25%;
  text-align: right;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 36px;
  color: #fff;
  padding-top: 3px;
}

.b2c-number-msisdn {
  width: 50%;
  padding: 0px 10px;
}

.b2c-number-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  letter-spacing: 0px;
  padding-left: 0px;
}

.b2c-number-input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  height: 45px;
  color: #ed1f24;
  width: 100%;
  border: none;
  background: #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  letter-spacing: 3px;
  padding-left: 10px;
}

.b2c-number-btn {
  width: 25%;
}

.b2c-number-btn button {
  border: 1px solid #fff;
  width: 49px;
  height: 45px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE4TDE0LjIyMjMgMTQuMjE1NkwxOCAxOFpNMTYuMzE1OCA5LjE1Nzg5QzE2LjMxNTggMTEuMDU2MyAxNS41NjE3IDEyLjg3NjkgMTQuMjE5MyAxNC4yMTkzQzEyLjg3NjkgMTUuNTYxNyAxMS4wNTYzIDE2LjMxNTggOS4xNTc4OSAxNi4zMTU4QzcuMjU5NSAxNi4zMTU4IDUuNDM4ODYgMTUuNTYxNyA0LjA5NjUgMTQuMjE5M0MyLjc1NDEzIDEyLjg3NjkgMiAxMS4wNTYzIDIgOS4xNTc4OUMyIDcuMjU5NSAyLjc1NDEzIDUuNDM4ODYgNC4wOTY1IDQuMDk2NUM1LjQzODg2IDIuNzU0MTMgNy4yNTk1IDIgOS4xNTc4OSAyQzExLjA1NjMgMiAxMi44NzY5IDIuNzU0MTMgMTQuMjE5MyA0LjA5NjVDMTUuNTYxNyA1LjQzODg2IDE2LjMxNTggNy4yNTk1IDE2LjMxNTggOS4xNTc4OVY5LjE1Nzg5WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==") no-repeat center;
}

.b2c-number--container {
  padding: 0 25px;
  height: 36px;
  background: #d71a1f;
  width: 100%;
}

.b2c-number-except {
  margin: 0 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-except-e {
  margin: 0 25px;
  height: auto;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-type {
  margin-left: 25px;
  margin-right: 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 9px;
}

.b2c-number-price {
  margin: 0 25px;
  height: 46px;
  background: #d71a1f;
  width: calc(100% - 50px);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  vertical-align: middle;
  margin-top: 30px;
}

.b2c-number-except-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-number-except-buttons {
  padding: 0px;
  min-height: 46px;
  overflow: hidden;
}

.b2c-number-except-btn {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #fff;
  width: 29px;
  height: 29px;
}

.b2c-number-except-btn-checked {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==") no-repeat;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 8px 8px 8px 0px;
  color: #ed1f24;
  width: 29px;
  height: 29px;
}

.b2c-number-except-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-number-except-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3LjE0MjgyTDcgMS4xNDI4MkwxIDcuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}

.b2c-number-type-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}

.b2c-number-type-select option {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.b2c-number-type-select {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  background: none;
  border: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 18px;
  margin-right: 16px;
  padding-right: 16px;
}

.b2c__btn {
  margin-left: 10px;
  font-size: 16px;
  height: auto !important;
  width: 22%;
}

.b2c-search-button {
  width: calc(100% - 50px);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  color: #424242;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  height: 48px;
  background: #ffffff;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  margin: 25px;
}

.b2c-search-button-red {
  color: #ffffff !important;
  background: #ed1f24 !important;
}

.b2c-pagination-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-top: 4px;
  color: #424242;
  width: 55%;
}

.b2c-pagination-title-sort {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #424242;
  background: #fff;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 4px 8px;
  border: none;
  width: 45%;
  text-align: right;
}

.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}

.b2c-badge {
  font-size: 14px;
}

.b2c-buy-btn {
  border: 2px solid #ed1f24;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  font-size: 16px;
  color: #ed1f24;
  height: 34px;
  width: 96px;
}

.b2c-page-msisdn {
  color: #000;
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 12px;
  letter-spacing: 2px;
}

.b2c-camket-text {
  font-size: 14px;
  margin-top: 12px;
}

.b2c-price-text {
  font-weight: 400;
  font-size: 24px;
  padding-top: 12px;
  letter-spacing: 0.5px;
  color: #424242;
}

.b2c-price-text b {
  color: #ed1f24;
}

.b2c-package-bg-selected {
  background: #f9f9f9;
}

.b2c-package-title-selected {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  text-align: left;
  width: 50%;
  font-size: 36px;
  color: #ed1f24;
}

.b2c-package-subtitle-selected {
  font-family: "Roboto";
  font-style: normal;
  text-align: right;
  font-weight: 700;
  font-size: 20px;
  width: 50%;
  color: #424242;
  padding-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: end;
}

.b2c-package-subtitle-selected p {
  padding-bottom: 2px;
}

.b2c-package-brief-selected {
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}

.b2c-package-brief-selected li {
  margin-top: 10px;
}

.b2c-package-brief-selected li::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
  padding-right: 10px;
  vertical-align: sub;
}

.b2c-package-brief-selected .pack-txt {
  text-align: left;
}

.b2c-package-title {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  text-align: center;
  width: 100%;
  font-size: 36px;
  color: #ed1f24;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjU5NDMgMTcuOTQ5N0MxNi4yMDM4IDE4LjM0MDMgMTUuNTcwNiAxOC4zNDAzIDE1LjE4MDEgMTcuOTQ5N0wxMy43NjU5IDE2LjUzNTVDMTMuMzc1MyAxNi4xNDUgMTIuNzQyMiAxNi4xNDUgMTIuMzUxNiAxNi41MzU1VjE2LjUzNTVDMTEuOTYxMSAxNi45MjYxIDExLjk2MTEgMTcuNTU5MiAxMi4zNTE2IDE3Ljk0OTdMMTUuMTgwMSAyMC43NzgyQzE1LjU3MDYgMjEuMTY4NyAxNi4yMDM4IDIxLjE2ODcgMTYuNTk0MyAyMC43NzgyTDIyLjI1MTEgMTUuMTIxM0MyMi42NDE3IDE0LjczMDggMjIuNjQxNyAxNC4wOTc2IDIyLjI1MTEgMTMuNzA3MVYxMy43MDcxQzIxLjg2MDYgMTMuMzE2NiAyMS4yMjc0IDEzLjMxNjYgMjAuODM2OSAxMy43MDcxTDE2LjU5NDMgMTcuOTQ5N1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMiAxNi41QzMyIDI1LjA2MDQgMjUuMDYwNCAzMiAxNi41IDMyQzcuOTM5NTkgMzIgMSAyNS4wNjA0IDEgMTYuNUMxIDcuOTM5NTkgNy45Mzk1OSAxIDE2LjUgMUMyNS4wNjA0IDEgMzIgNy45Mzk1OSAzMiAxNi41WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 10px;
}

.b2c-package-subtitle {
  font-family: "Roboto";
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  color: #424242;
}

.b2c-package-brief {
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}

.b2c-package-brief li {
  margin-top: 10px;
}

.b2c-package-brief li::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNTc0ODkgOC45MDE1OUM4LjE4NDM2IDkuMjkyMTIgNy41NTEyIDkuMjkyMTIgNy4xNjA2OCA4LjkwMTU5TDYuNzU5MDkgOC41QzYuNTU0OTggOC4yOTU5IDYuMjI0MDYgOC4yOTU5IDYuMDE5OTYgOC41VjguNUM1LjgxNTg1IDguNzA0MTEgNS44MTU4NSA5LjAzNTAzIDYuMDE5OTYgOS4yMzkxM0w3LjE2MDY4IDEwLjM3OTlDNy41NTEyIDEwLjc3MDQgOC4xODQzNiAxMC43NzA0IDguNTc0ODkgMTAuMzc5OUwxMS4xOTM5IDcuNzYwODdDMTEuMzk4IDcuNTU2NzcgMTEuMzk4IDcuMjI1ODUgMTEuMTkzOSA3LjAyMTc0VjcuMDIxNzRDMTAuOTg5OCA2LjgxNzY0IDEwLjY1ODggNi44MTc2NCAxMC40NTQ3IDcuMDIxNzRMOC41NzQ4OSA4LjkwMTU5WiIgZmlsbD0iIzQyNDI0MiIvPgo8cGF0aCBkPSJNMTYgOC41QzE2IDEyLjY0MjEgMTIuNjQyMSAxNiA4LjUgMTZDNC4zNTc4NiAxNiAxIDEyLjY0MjEgMSA4LjVDMSA0LjM1Nzg2IDQuMzU3ODYgMSA4LjUgMUMxMi42NDIxIDEgMTYgNC4zNTc4NiAxNiA4LjVaIiBzdHJva2U9IiM0MjQyNDIiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");
  padding-right: 10px;
}

.b2c-package-brief .pack-txt,
.b2c-package-brief-selected .pack-txt {
  text-align: left;
}

.b2c-package-brief-selected .pack-txt,
.b2c-package-brief .pack-txt {
  margin-bottom: 10px;
}

.b2c-float-bg {
  height: 120px;
  background: rgba(237, 31, 36, 1);
  position: absolute;
}

.b2c-cart-price {
  position: absolute;
  min-width: 100px;
  width: auto;
  height: 63px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  right: 20px;
  background: #18d3c9;
  font-style: italic;
  font-size: 16px;
  color: #fff;
  font-weight: 900;
  text-align: center;
  padding: 13px;
}

.b2c-cart-price span {
  font-size: 24px;
}

.b2c-cart-add-more {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.b2c-cart-info-title {
  height: 46px;
  background: #eaeaea;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  padding: 10px 24px;
  border-bottom: 1px solid #e0e0e0;
}

.b2c-cart-info-title-text {
  height: 46px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  padding: 1rem 1rem;
}

.b2c-agree-text button {
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}

.b2c-store-colapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAyTDYuNSA3TDIgMiIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}

.b2c-store-nocolapse {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSA3TDYuNSAyTDIgNyIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}

.b2c-bg-selected {
  background: #17e0d5;
}

.b2c-bg-selected .b2c-page-msisdn,
.b2c-bg-selected .b2c-price-text,
.b2c-bg-selected .b2c-camket-text,
.b2c-bg-selected .b2c-price-text b {
  color: #fff;
}

.b2c-text-solo h1 {
  font-size: 28px;
  letter-spacing: 3px;
  font-weight: 500;
  color: #fff;
}

.b2c-text-solo p {
  color: #fff;
  font-size: 16px;
  padding: 0 10%;
}

.b2c-search-button-red:disabled {
  background: #999999 !important;
}

.b2c-cart-bg-del {
  border-radius: 26.8px;
  -webkit-border-radius: 26.8px;
  border: 1.5px solid #ed1f24;
  padding: 6px 16px;
}

.b2c-price-texts {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.b2c-rate-text {
  font-size: 20px;
  line-height: 1.4;
}

.vue-star-rating {
  display: block !important;
}

.b2c-ghtk-fee {
  font-size: 14px;
  text-decoration-line: line-through !important;
}

.b2c-o-h {
  max-width: 100%;
  overflow-x: hidden;
}

.text1 {
  font-size: 12px;
  line-height: 1.5;
}

.b2c-page-bg .custom-checkbox.custom-control {
  padding-left: 2.2rem;
}

.b2c-page-bg .custom-control-label {
  line-height: 1.2;
}

.b2c-pc-pack-content {
  min-height: 550px;
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
}

.b2c-pc-pack-content .package {
  width: 558px;
  height: 304px;
  background: #ed1f24;
  border-radius: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 160px;
  position: relative;
}

.b2c-pc-pack-content .package h4 {
  font-size: 16px;
  text-align: center;
  padding-top: 30px;
  color: #fff;
}

.b2c-pc-pack-content .package h2 {
  font-weight: 600;
  letter-spacing: 3px;
  font-size: 36px;
  text-align: center;
  color: #fff;
}

.b2c-pc-pack-content .package .subpackage {
  width: 369px;
  height: auto;
  background: #fff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  left: 94.5px;
  margin-top: 20px;
}

.b2c-pc-pack-content .package .subpackage .sub-header {
  border-bottom: 1px solid #0000001a;
  display: flex;
  height: 64px;
}

.b2c-pc-pack-content .package .subpackage h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: #ed1f24;
  font-style: italic;
  width: 50%;
  text-align: left;
  padding: 14px 0px 0px 28px;
}

.b2c-pc-pack-content .package .subpackage h4 {
  width: 50%;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #424242;
  padding-top: 10px;
  padding-right: 30px;
  text-align: right;
}

.b2c-pc-pack-content .package .subpackage h4 > span {
  font-weight: 400;
  font-size: 14px;
}

.b2c-pc-pack-content .package .subpackage .pack-txt {
  padding: 6px 15px 0px 15px;
  max-width: 100%;
}

.b2c-pc-agree,
.b2c-pc-pack-content .pc-step2-button {
  max-width: 558px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
}

.b2c-pc-pack-content .package .b2c-cart-bg {
  max-width: 369px;
  margin-right: auto;
  margin-left: auto;
}

.b2c-pc-pack-content .b2c-cart-add-more {
  max-width: 369px;
  margin-right: auto;
  margin-left: auto;
}

.b2c-pc-pack-content .b2c-pc-payment .package {
  height: 200px;
}

.b2c-pc-pack-content .b2c-pc-info {
  margin-top: 50px;
}

.b2c-pc-pack-content .b2c-pc-info .card-header {
  background: #f8f8f8;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.b2c-pc-pack-content .package .pack-txt {
  padding: 6px 15px 0px 15px;
  max-width: 100%;
}

.b2c-pc-pack-content .b2c-package-bg-selected {
  max-width: 457px;
  margin-left: auto;
  margin-right: auto;
}

.space-between {
  justify-content: space-between;
}

.delivery-type {
  color: #424242;
  padding-top: 20px;
  padding-bottom: 20px;
}

.border-bt {
  border-bottom: 0.7px solid #bfbfbf;
}

.info-delivery__title {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

.info-delivery__sub-title {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-style: italic;
}

.check-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.check-box > img {
  width: 22px;
  height: 22px;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.error {
  border: 1px solid red !important;
}

.info-delivery--subtile {
  font-size: 1.2rem;
}

.content-body {
  font-size: 14px !important;
}

.btn-secondary {
  background: #6c757d !important;
  border: #6c757d !important;
  padding: 0.786rem 1.5rem !important;
  font-size: 14px !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
}

.btn-primary {
  font-size: 14px !important;
  font-family: "Roboto";
  padding: 0.786rem 1.5rem !important;
  font-style: normal;
  font-weight: 700;
}

.inputText {
  font-size: 18px;
}

.paymentBox {
  height: 52px;
  margin-bottom: 4%;

}

.custom-checkbox .custom-control-label::before {
  border-radius: 50%;
}

.buttonZaloMomo {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);;
  border-radius: 8px;
  margin: 2%;
  width: 231px;
  height: 80px
}

.borderPaymentBox {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.buttonZaloMomoActive {
  border: 1px solid #FF0000;
}

[dir] .custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: white;
  border: #d8d6de solid 1px;
}

[dir] .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgb(255 0 6);
}

@media screen and (max-width: 450px) {
  .buttonZaloMomo {
    width: 45%;
  }

  .zaloLogo {
    width: 85%;
    height: 45%;
  }

  .inputText {
    font-size: 16px;
  }

  .b2c__btn {
    width: 45%;
  }

  .modal .modal-content {
    width: 100%;
  }
}
</style>
